import {GetterTree} from "vuex";
import {StateInterface} from "@/store";
import IMaster from "@/models/maestre.model";
import {ConstructionState} from "@/store/utils/constructions/state";

const getters: GetterTree<ConstructionState, StateInterface> = {
    getPage({items}: ConstructionState): number {
        return items.page ?? 1;
    },
    getTotal({items}: ConstructionState): number {
        return items.total ?? 0;
    },
    getItems({items}: ConstructionState): IMaster[] {
        return items?.items ?? [];
    },
    isLoading({isLoading}: ConstructionState): boolean {
        return isLoading;
    },
}
export default getters;
