import { Options, Vue } from 'vue-class-component';
import CardProject from '@/components/smarts/card-project/CardProject.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import { ICompany } from '@/models/company.model';
import { IAddress } from '@/models/address.model';
import { IImage } from '@/models/image.model';

@Options({
  components: {
    CardProject
  },
})
export default class CompaniesCloseMe extends Vue {

  citiesValid = [
    'Madrid', 'Barcelona', 'Valencia', 'Sevilla', 'Bilbao', 'Zaragoza'
  ];
  store = useStore<StateInterface>();
  private cityActive?: IAddress = null;
  companies: ICompany[] = [];
  cities: IAddress[] = [];

  getImageByType(images: IImage[], type: string): string|null {
    const image = images.find((image: IImage) => {
      if (image.type === type) {
        return image;
      }
    })
    if (!image) {
      return null;
    }

    return image.url;
  }

  async getCompanies(city: IAddress): Promise<void> {
    const retorno = await this.store.dispatch('companies/getBy', { perPage: 4, cityName: city.province });
    this.companies = retorno.items ?? [];
  }

  mounted(): void {
    this.store.watch(
      (_: StateInterface) => this.store.getters['provinces/getItems'],
      (_: IAddress[]) => this.loadCities(),
      {
        deep: true
      }
    );
    this.loadCities();
  }

  loadCities(): void {
    this.cities = this.store.getters['provinces/getItems'].filter((address: IAddress) => {
      return this.citiesValid.filter(e => address.province.toLowerCase() == e.toLowerCase()).length > 0;
    });
    if ( !this.cityActive && this.cities.length)
      this.getCompanies(this.cities[0]);
  }

  onActiveCity(value: IAddress): void {
    this.cityActive = value;
    this.getCompanies(value);
  }


  goTo({ clear = false }: { clear?: boolean }): void {
    this.store.dispatch('companies/saveFilter',
      clear
        ? {}
        : { city: this.cityActive });
    this.$router.push('/companies');
  }


}
