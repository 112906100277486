import {MutationTree} from "vuex";
import {IPaginate} from "@/models/paginate.model";
import IMaster from "@/models/maestre.model";
import {BuildingsState} from "@/store/utils/buildings/state";

const mutation: MutationTree<BuildingsState> = {
    setItems(state: BuildingsState, items: IPaginate<IMaster>) {
        state.items = items;
        state.isLoading = false;
    },
    addItems(state: BuildingsState, {items}: IPaginate<IMaster>) {
        state.items.items = [this.state.items.items, ...items];
        state.isLoading = false;
    },
    setLoading(state: BuildingsState, loading: boolean) {
        state.isLoading = loading;
    },


}


export default mutation;