import { MutationTree } from 'vuex';
import { IPaginate } from '@/models/paginate.model';
import { ProvincesState } from '@/store/utils/provinces/state';
import { IAddress } from '@/models/address.model';

const mutation: MutationTree<ProvincesState> = {
  setItems(state: ProvincesState, items: IPaginate<IAddress>) {
    state.items = items;
    state.isLoading = false;
  },
  addItems(state: ProvincesState, { items }: IPaginate<IAddress>) {
    state.items.items = [this.state.items.items, ...items];
    state.isLoading = false;
  },
  setLoading(state: ProvincesState, loading: boolean) {
    state.isLoading = loading;
  },


};


export default mutation;
