import {Options, Vue} from "vue-class-component";
import {Quote} from "@/models/quote.model";

@Options({
    components: {},
})
export default class QuotesSlider extends Vue {

    comments: Quote[] = [
        {
            comment: "He conseguido más trabajo con Triente que a través de Google. Desde que estoy en Triente, me contactan mucho más.",
            avatar: "https://gravatar.com/avatar/8505a0eb7a843af6d781e9c5220d9584?s=400&d=robohash&r=x",
            name: "Fernando Fernández",
            profession: "Carpintero metálico"
        } as Quote,
        {
            comment: "He conseguido más trabajo con Triente que a través de Google. Desde que estoy en Triente, me contactan mucho más.",
            avatar: "https://gravatar.com/avatar/8505a0eb7a843af6d781e9c5220d9584?s=400&d=robohash&r=x",
            name: "Fernando Fernández",
            profession: "Carpintero metálico"
        } as Quote,
        {
            comment: "He conseguido más trabajo con Triente que a través de Google. Desde que estoy en Triente, me contactan mucho más.",
            avatar: "https://gravatar.com/avatar/8505a0eb7a843af6d781e9c5220d9584?s=400&d=robohash&r=x",
            name: "Fernando Fernández",
            profession: "Carpintero metálico"
        } as Quote,
    ];

}
