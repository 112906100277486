import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, {EmployeesState} from "@/store/utils/employees/state";


const employeesModule: Module<EmployeesState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default employeesModule;