import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3 input-ui-text-area" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["value", "id", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.idInput,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createElementVNode("textarea", {
      autocomplete: "off",
      style: {"min-height":"150px","max-height":"400px"},
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatedEmit($event.target.value))),
      class: _normalizeClass([{'is-invalid':_ctx.vuelidate?.$silentErrors.length && _ctx.vuelidate.$dirty}, "form-control"]),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.vuelidate?.$touch())),
      id: _ctx.idInput,
      placeholder: _ctx.placeholder
    }, null, 42, _hoisted_3),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vuelidate?.$silentErrors, (msj, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "invalid-feedback",
        key: 'mss-'+index
      }, _toDisplayString(msj.$message), 1))
    }), 128))
  ]))
}