import { MutationTree } from 'vuex';
import { IPaginate } from '@/models/paginate.model';
import IMaster from '@/models/maestre.model';
import { TypeCompanyState } from '@/store/utils/type_company/state';

const mutation: MutationTree<TypeCompanyState> = {
  setItems(state: TypeCompanyState, items: IPaginate<IMaster>) {
    state.items = items;
    state.isLoading = false;
    if (items && items.items && items.items.length)
      this.state.selected = items.items[0];
  },
  setLoading(state: TypeCompanyState, loading: boolean) {
    state.isLoading = loading;
  },
  select(state: TypeCompanyState, company: IMaster) {
    localStorage.setItem('type', JSON.stringify(company));
    state.selected = company;
  },
};


export default mutation;
