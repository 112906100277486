import {Options, Vue} from 'vue-class-component';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';

@Options({
    props: {
        msg: String
    }
})
export default class Footer extends Vue {
    store = useStore<StateInterface>();
    msg!: string;

    footer: { [k: string]: { text: string; link: string }[] } = {
        "Sobre Triente": [
            {text: 'Conoce Triente', link: '/know-me'},
            {text: 'Únete a Triente', link: '/sign-in'},
            // {text: 'Preguntas frecuentes', link: ''},
            // {text: 'Pricing', link: ''},
            {text: 'Contacto', link: 'mailto:admin@triente.es?subject=Correo Contacto'},
            // {text: 'Contacto', link: ''},
        ],
        "Busca por Ubicación": [
            {text: 'Madrid', link: '/'},
            {text: 'Barcelona', link: '/'},
            {text: 'Valencia', link: '/'},
            {text: 'Murcia', link: '/'},
            {text: 'Zaragoza', link: '/'},
        ],
        "Legal": [
            {text: 'Política de Privacidad', link: '/'},
            {text: 'Aviso Legal', link: '/'},
            {text: 'Condiciones de uso', link: '/'},
        ],
    };

    async search(province: string) {
        const filter = {};
        filter["type"] = "34196bd5-4ca5-46db-af0c-c04ac09a76f3";
        filter["provinceName"] = province;
        await this.store.dispatch('companies/saveFilter', filter);
        await this.$router.push({ path: 'companies' });
    }
}
