import { Options, Vue } from 'vue-class-component';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';
import UiText from '@/components/form/ui-text/UiText.vue';
import VueMultiselect from 'vue-multiselect';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import UrlApi from '@/models/url-api.model';


@Options({
  emits: [
    'update:modelValue'
  ],
  components: {
    UiFormMessage,
    UiText,
    VueMultiselect
  },
  watch: {
    modelValue(value, oldValue) {
      if (!value) {
        this.txtValue = '';
        this.isLoading = false;
      }
      if (typeof value === "object") {
        this.txtValue = value
      }
      if (value && (oldValue === null || (typeof oldValue == 'object' && Object.values(oldValue).length === 0))) {
        this.asyncFind(value, !oldValue);
      }
    },
    queryParams(value, oldValue) {
      if (Object.values(oldValue).filter(e => e).length && JSON.stringify(value) !== JSON.stringify(oldValue)) {
        if ( !this.multiple) {
          this.txtValue = '';
          this.$emit('update:modelValue', '');
        }
        this.asyncFind('');
      }
    },
    disabled() {
      this.asyncFind('');
    }
  },
  props: {
    label: String,
    placeholder: String,
    options: Array,
    multiple: Boolean,
    keyValue: {
      key: String,
      value: String
    },
    modelValue: undefined,
    vuelidate: undefined,
    disabled: Boolean,
    offSearchable: Boolean,
    urlApi: String,
    queryParams: Object,
    parent: [Object, String],
    typeCompany: String,
    noOption: {
      type: String,
      default: "No encontrado"
    }
  }
})
export default class UiSelectType<T> extends Vue {
  store = useStore<StateInterface>();
  label = '';
  placeholder = '';
  options: T[] = [];
  optionsApi: T[] = [];
  isActive = false;
  offSearchable = true;
  multiple = false;
  isLoading = false;
  disabled = false;
  queryParams = {};
  modelValue;
  txtValue = null;
  keyValue: {
    key: '',
    value: ''
  };
  timer: number;
  urlApi: UrlApi;
  listFilter: T[] = [];
  parent = null;
  typeCompany = null;
  noOption = null;

  asyncFind(query: string, selectFirst = false): void {
    if (this.disabled || !this.urlApi) {
      this.filterOptions(query);
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.isLoading = true;
    this.timer = setTimeout(() => {
      this.loadOptions(query, selectFirst);
    }, 500);
  }

  async loadOptions(query, selectFirst = false): Promise<void> {
    if (this.disabled) {
      this.isLoading = false;
      return;
    }

    const params = {
      ...this.queryParams,
      [this.keyValue.key]: query && typeof query === 'object'
        ? ''
        : query
    };
    if (this.optionsApi.length && query) {
      const exist = this.optionsApi.filter(e => this.keyValue.key
        ? e[this.keyValue.key] === query[this.keyValue.key]
        : JSON.stringify(e) == JSON.stringify(query));
        this.isLoading = false;
      if (exist.length) return;
    }

    let retorno: T[] = await this.store.dispatch('helper/getBy',
      { url: this.urlApi, params });

    if (!this.parent) {
      retorno = (retorno ?? []).reduce((acc, current) => {
        const x = acc.find(item => item[this.keyValue.key] === current[this.keyValue.key]);
        return !x
          ? acc.concat([current])
          : acc;
      }, []);
    }

    if (this.parent) {
      retorno = retorno.filter((element: any) => element.parent);
      retorno = retorno.filter((element: any) => element.parent.id === this.parent.id);
    }

    if (this.typeCompany) {
      retorno = retorno.filter((element: any) => !element.parent);
      if (this.typeCompany === "34196bd5-4ca5-46db-af0c-c04ac09a76f3") {
        retorno = retorno.filter((element: any) => {
          if (element.id === "a45867ef-a69a-4a44-aba7-74c5f5371b8b" ||
          element.id === "4ad1505f-23d2-4f52-a9e8-aa7240cbc020" ||
          element.id === "ce03569c-aaad-44b0-9c1d-78ca039cf2c3" ||
          element.id === "1df29aaf-c0e5-465a-9570-0217e01f9401") {
            return element;
          }
        });
      } else {
        retorno = retorno.filter((element: any) => {
          if (element.id != "a45867ef-a69a-4a44-aba7-74c5f5371b8b" &&
          element.id != "4ad1505f-23d2-4f52-a9e8-aa7240cbc020" &&
          element.id != "ce03569c-aaad-44b0-9c1d-78ca039cf2c3" &&
          element.id != "1df29aaf-c0e5-465a-9570-0217e01f9401") {
            return element;
          }
        });
      }
    }

    this.optionsApi = [...retorno];
    if (this.modelValue && this.optionsApi.length == 1 && selectFirst) {
      this.txtValue = this.optionsApi[0];
    }
    this.isLoading = false;
  }

  onSelected(_: T): void {
    this.txtValue = _[this.keyValue.value];
    if (this.multiple) {
      setTimeout(() => {
        this.$emit('update:modelValue', this.txtValue);
      }, 200);
      return;
    }
    this.$emit('update:modelValue', _);
  }

  onChange(_): void {
    if (this.multiple) {
      this.txtValue = this.txtValue.filter(e => e[this.keyValue.key] !== _[this.keyValue.key]);
      this.$emit('update:modelValue', this.txtValue);
    }
    this.$emit("update:modelValue", '')
    setTimeout(() => this.asyncFind(this.txtValue), 300);
  }

  isValidInput(): boolean {
    return this.modelValue &&
    (!this.txtValue || (typeof this.txtValue == 'object' && Object.values(this.txtValue).length === 0))
  }

  mounted(): void {
    setTimeout(() => {
      if (this.isValidInput()) {
        const val =
          !this.multiple && this.keyValue?.key
            ? {
              [this.keyValue.key]: typeof this.modelValue === 'object'
                ? this.modelValue[this.keyValue.key]
                : this.modelValue
            }
            : this.modelValue;
        this.txtValue =
          this.multiple
            ? Array.isArray(val)
              ? [...val]
              : [val]
            : val;

      }
      this.asyncFind(this.txtValue ?? '');
    }, 500);
  }

  normalizedContains(str: any): any {
    if (typeof str === 'string') return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    if (typeof str === 'object' && this.keyValue?.key && typeof str[this.keyValue.key] == 'string')
      return str[this.keyValue.key].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    return str;
  }

  private filterOptions(query: string) {
    if ( !query || !this.options || !this.options.length || Array.isArray(query)) return;
    this.listFilter =
      this.keyValue?.key
        ? this.options.filter(opt => {
          const normalizedValue = this.normalizedContains(opt[this.keyValue.key])
          const normalizedQuery = this.normalizedContains(query)
          return normalizedValue.includes(normalizedQuery);
        })
        : this.options.filter((e: T) => e.toString().includes(query));
  }
}
