import { Options, Vue } from 'vue-class-component';
import { ICompany } from '@/models/company.model';

@Options({
  components: {},
  props: {
    company: Object
  }
})
export default class AreaAmountCompany extends Vue {
  company!: ICompany;

  detailValues: { title: string, icon: string, getValue: unknown }[] = [
    {
      title: 'UBICACIÓN',
      icon: 'icon-fi_map-pin',
      getValue: (ob: ICompany): string => ob?.address?.province ?? '-'
    },
    {
      title: 'SUPERFICIE EdIFICABLE',
      icon: 'icon-fi_area',
      getValue: (ob: ICompany): string => `${new Intl.NumberFormat('es-ES').format(ob.area?.min) ?? 0} - ${ob.area?.max > 20000 ? 'Más de 20.000' : new Intl.NumberFormat('es-ES').format(ob.area?.max) ?? 0} m²`
    },
    {
      title: 'importe proyectos',
      icon: 'icon-fi_money',
      getValue: (ob: ICompany): string => `${new Intl.NumberFormat('es-ES').format(ob.budget?.min) ?? 0} - ${ob.budget?.max > 50000000 ? 'Más de 50.000.000' : new Intl.NumberFormat('es-ES').format(ob.budget?.max) ?? 0}€`
    },
    {
      title: 'EMPLEADOS',
      icon: 'icon-fi_users',
      getValue: (ob: ICompany): string => ob?.employees['label'] ?? '-'
    }
  ];
}
