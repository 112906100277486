import { Options, Vue } from 'vue-class-component';
import BannerNavigation from '@/components/smarts/ui-nav-bar/banner-navigation/BannerNavigation.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import IMaster from '@/models/maestre.model';
import IActivity from '@/models/activity.model';
import { MENU_MORE } from '@/components/smarts/ui-nav-bar/nav-bar/NavBar';

@Options({
  emits: ['onBack'],
  components: {
    BannerNavigation
  },
  props: {
    msg: String
  }
})
export default class ExploreCompanies extends Vue {
  moreOpen = MENU_MORE.COMPANIES;
  store = useStore<StateInterface>();

  get typeCompanies(): IMaster[] {
    return this.store.getters['type_company/getItems'];
  }

  selectCompany(company: IMaster): void {
    this.store.dispatch('type_company/selected', company);
    this.selectActivity(null);

    if (company?.id === null) {
      this.$router.push('/companies').then(() => {
        this.store.dispatch('companies/saveFilter', { }).then(() => {
          this.moreOpen = null;
          this.onClose();
        });
      });
    }
  }

  get companySelected(): IMaster {
    return this.store.getters['type_company/getSelected'];
  }

  get activities(): IActivity[] {
    return this.store.getters['activities/getItems'];
  }

  get activitySelected(): IActivity {
    return this.store.getters['activities/getSelected'];
  }

  selectActivity(activity: IActivity): void {
    if (activity?.id === this.activitySelected?.id) {
      this.store.dispatch('activities/select', undefined);
      return;
    }
    this.store.dispatch('activities/select', activity);

    if (activity?.id === null) {
      this.$router.push('/companies').then(() => {
        this.store.dispatch('companies/saveFilter', { type: [this.companySelected] }).then(() => {
          this.moreOpen = null;
          this.onClose();
        });
      });
    }
  }

  get subActivities(): IActivity[] {
    return this.store.getters['sub_activities/getItems'];
  }

  get subActivitySelected(): IActivity {
    return this.store.getters['sub_activities/getSelected'];
  }

  async selectedSubActivity(activity: IActivity): Promise<void> {
    if (activity?.id === null) {
      this.$router.push('/companies').then(() => {
        this.store.dispatch('companies/saveFilter', { parent: [this.activitySelected] }).then(() => {
          this.moreOpen = null;
          this.onClose();
        });
      });
      return;
    }

    await this.store.dispatch('sub_activities/select', activity);
    await this.store.dispatch('companies/saveFilter', { activities: [this.subActivitySelected] });
    // await this.$router.push({ path: 'companies' });
    await this.$router.push('/companies');
    this.moreOpen = null;
    this.onClose();
  }

  onClose(): void {
    this.$emit('onBack', null);
  }
}
