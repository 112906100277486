import { IAddress } from "@/models/address.model";
import { CityTreeType } from "@/types/CityTreeType";
import VueTreeCheckElementsAllItsChildrenAreChecked from "./VueTreeCheckElementsAllItsChildrenAreChecked";

class VueTreeElementsGetter
{
  public static get(cities: IAddress[], selected: IAddress[]): CityTreeType
  {
    const allSpain: CityTreeType = {
      id: '1',
      text: 'España',
      nodes: [],
      checkable: true,
      selectable: false,
      state: {
        checked: false,
        expanded: true,
      }
    }

    for (const city of cities) {
      const community = city.community;
      let child = VueTreeElementsGetter.getChildByLabel(community, allSpain.nodes);
      if (!child) {
        child = {
          id: '',
          text: community,
          nodes: [],
          checkable: true,
          selectable: false,
          state: {
            checked: false,
            expanded: false,
          }
        };
        allSpain.nodes.push(child);
      }
      child.nodes.push({
        id: city.id,
        text: city.province,
        nodes: undefined,
        checkable: true,
        selectable: false,
        state: {
          checked: VueTreeElementsGetter.isSelected(city.province, selected),
          expanded: false,
        }
      });
    }
    allSpain.nodes.sort((a: CityTreeType, b: CityTreeType) => {
      if (a.text > b.text) {
        return 1;
      }
      return -1;
    })

    VueTreeCheckElementsAllItsChildrenAreChecked.check(allSpain);

    return allSpain;
  }

  private static isSelected(text: string, selected: IAddress[]): boolean
  {
    const city = selected.find((city: IAddress) => {
      return city.province === text;
    });

    return !!city;
  }

  private static getChildByLabel(text: string, cities: CityTreeType[]): CityTreeType
  {
    return cities.find((city: CityTreeType) => {
      return city.text === text;
    })
  }
}

export default VueTreeElementsGetter;
