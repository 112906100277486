import { createStore } from 'vuex';
import { AuthState } from '@/store/auth/state';
import authModule from '@/store/auth';
import { CitiesState } from '@/store/utils/cities/state';
import citiesModule from '@/store/utils/cities';
import { ActivitiesState } from '@/store/utils/activities/state';
import { SubActivitiesState } from '@/store/utils/sub_activities/state';
import activitiesModule from '@/store/utils/activities';
import subActivitiesModule from '@/store/utils/sub_activities';
import { ProfileState } from '@/store/profile/state';
import profileModule from '@/store/profile';
import { TypeCompanyState } from '@/store/utils/type_company/state';
import typeCompanyModule from '@/store/utils/type_company';
import { BuildingsState } from '@/store/utils/buildings/state';
import constructionsModule from '@/store/utils/constructions';
import { ConstructionState } from '@/store/utils/constructions/state';
import buildingModule from '@/store/utils/buildings';
import { EmployeesState } from '@/store/utils/employees/state';
import employeesModule from '@/store/utils/employees';
import { HelperState } from '@/store/utils/helper/state';
import helperModule from '@/store/utils/helper';
import companiesModule from '@/store/companies';
import { CompaniesState } from '@/store/companies/state';
import { ProvincesState } from '@/store/utils/provinces/state';
import provincesModule from '@/store/utils/provinces';

export interface StateInterface {
  auth: AuthState,
  cities: CitiesState,
  activities: ActivitiesState,
  sub_activities: SubActivitiesState,
  buildings: BuildingsState,
  constructions: ConstructionState,
  type_company: TypeCompanyState,
  profile: ProfileState,
  employees: EmployeesState,
  helper: HelperState,
  companies: CompaniesState,
  provinces: ProvincesState,
}

export default createStore<StateInterface>({
  modules: {
    auth: authModule,
    helper: helperModule,
    cities: citiesModule,
    activities: activitiesModule,
    sub_activities: subActivitiesModule,
    buildings: buildingModule,
    constructions: constructionsModule,
    type_company: typeCompanyModule,
    profile: profileModule,
    employees: employeesModule,
    provinces: provincesModule,
    companies: companiesModule
  }
});
