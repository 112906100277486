import { ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import { IPaginate } from '@/models/paginate.model';
import { IKeyValue } from '@/models/key-value.model';
import filterQuery from '@/helpers/filter-query';
import IActivity from '@/models/activity.model';
import UrlApi from '@/models/url-api.model';
import { ProvincesState } from '@/store/utils/provinces/state';

const url = `v1/${UrlApi.PROVINCES}`;
const actions: ActionTree<ProvincesState, StateInterface> = {


  async get({ commit }, extra?: IKeyValue): Promise<void> {
    const extras = { ...extra ?? {} };
    if ( !extras || extras['main'] == undefined)
      extras['main'] = true;

    const params = { search: filterQuery(extras) };
    params['perPage'] = 200;

    const { data, status } = await api.get<IPaginate<IActivity>>(url, { params });
    if (status === 200) {
      commit('setItems', data);
    }
  },

};


export default actions;
