import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "fw-bold fs-5" }
const _hoisted_3 = { class: "icon-fi_plus fs-5" }
const _hoisted_4 = { class: "icon-fi_minus" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (q, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: _ctx.labelTab(index),
      class: "question_item"
    }, [
      _createElementVNode("div", {
        class: "d-flex justify-content-between py-3",
        onClick: ($event: any) => (_ctx.onSwitcher(index))
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(q.header), 1),
        _withDirectives(_createElementVNode("span", _hoisted_3, null, 512), [
          [_vShow, !_ctx.isActive(index)]
        ]),
        _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
          [_vShow, _ctx.isActive(index)]
        ])
      ], 8, _hoisted_1),
      _createVNode(_Transition, { name: "bounce" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", { class: "text-cement-gray pb-4" }, _toDisplayString(q.body), 513), [
            [_vShow, _ctx.isActive(index)]
          ])
        ]),
        _: 2
      }, 1024)
    ]))
  }), 128))
}