import { MutationTree } from 'vuex';
import { IPaginate } from '@/models/paginate.model';
import { ActivitiesState } from '@/store/utils/activities/state';
import IActivity from '@/models/activity.model';

const mutation: MutationTree<ActivitiesState> = {
  setItems(state: ActivitiesState, items: IPaginate<IActivity>) {
    state.items = items;
    state.isLoading = false;
  },
  select(state: ActivitiesState, activity: IActivity) {
    localStorage.setItem('activity', JSON.stringify(activity));
    state.selected = activity;
  },
  addItems(state: ActivitiesState, { items }: IPaginate<IActivity>) {
    state.items.items = [this.state.items.items, ...items];
    state.isLoading = false;
  },
  setLoading(state: ActivitiesState, loading: boolean) {
    state.isLoading = loading;
  },


};
export default mutation;
