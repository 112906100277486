import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-around align-items-center align-self-md-center w-100 area-amount-company flex-column-sm" }
const _hoisted_2 = { class: "px-2 title_area_amount" }
const _hoisted_3 = { class: "fw-bold text-uppercase hidden-xs" }
const _hoisted_4 = { class: "content_area_amount" }
const _hoisted_5 = { class: "text-cement-gray" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailValues, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'detail-'+index,
        class: "detail-company d-flex justify-content-md-center align-items-md-center flex-md-column px-2"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: _normalizeClass([item.icon, "me-2 hidden-xs"])
          }, null, 2),
          _createElementVNode("small", _hoisted_3, _toDisplayString(item.title), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass([item.icon, "me-2 only-xs"])
          }, null, 2),
          _createElementVNode("small", _hoisted_5, _toDisplayString(item.getValue(_ctx.company)), 1)
        ])
      ]))
    }), 128))
  ]))
}