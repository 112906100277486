import { ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import { IPaginate } from '@/models/paginate.model';
import IMaster from '@/models/maestre.model';
import { ProfileState } from '@/store/profile/state';
import { Project } from '@/models/project.model';
import { IUser } from '@/models/user.model';
import { v4 as uuidv4 } from 'uuid';
import { ICompany } from '@/models/company.model';
import toast from '@/plugins/toast.plugin';
import transformOnlyId from '@/helpers/transform-only-id';

const actions: ActionTree<ProfileState, StateInterface> = {


  async getProjects({ commit }): Promise<void> {

    const { data, status } = await api.get<IPaginate<Project>>(`/v1/projects`);
    if (status === 200) {
      commit('setProject', data);
    }
  },
  async addProjects({ commit }, project: Project): Promise<void> {

    const { data, status } = await api.post<IPaginate<Project>>(`/v1/projects`, { id: uuidv4(), project });
    if (status === 200) {
      commit('addProject', data);
    }
  },
  async patchProject({ commit }, project: Partial<Project>): Promise<void> {

    const { data, status } = await api.patch<Project>(`/v1/projects/` + project.id, project);
    if (status === 200) {
      commit('patchProfile', data);
    }
  },
  async deleteProfile({ commit }, id: string): Promise<void> {

    const { status } = await api.delete<void>(`/v1/projects/` + id);
    if (status === 200) {
      commit('deleteProject', id);
    }
  },

  async patchCompany({ commit }, company: ICompany): Promise<void> {
    const { id, ...data } = company;
    const data2: any = data;
    const buildings = data.buildings.filter(e => !!e).map(obj => transformOnlyId(obj, true));
    const activities = data.activities.filter(e => !!e).map(obj => transformOnlyId(obj, true));
    const cities = data.cities.filter(e => !!e).map(obj => transformOnlyId(obj, true));
    const constructions = data.constructions.filter(e => !!e).map(obj => transformOnlyId(obj, true));

    const upCom = {
      "type": data2?.type,
      "name": data2.name,
      "fullName": data2.fullName,
      "description": data2.description,
      "cif": data2.cif,
      "cnae": data2.cnae.id,
      "employees": data2.employees.id,
      "address" : {
        "address": data2.address.address,
        "zipcode": data2.address.zipcode,
        "province": data2.address.province,
        "city": data2.address.city,
        "country": data2.address.country,
      },
      "contact" : {
        "email": data2.contact.email,
        "phone": data2.contact.phone,
        "website": data2.contact.website,
      },
      "networks": data2.networks,
      "area": {
        "min": data2.area.min,
        "max": data2.area.max,
      },
      "budget": {
        "min": data2.budget.min,
        "max": data2.budget.max,
      }
    };
    if (constructions.length > 0)
      upCom["constructions"] = constructions;

    if (buildings.length > 0)
      upCom["buildings"] = buildings;

    upCom["activities"] = activities;

    if (cities.length > 0)
      upCom["cities"] = cities;


    const photo = company.images?.find(img => img.type === "Photo");
    const logo = company.images?.find(img => img.type === "Logo");
    const picture = company.images?.find(img => img.type === "Picture");

    const images = [];
    if (photo && photo['content']) {
      photo['type'] = "Photo";
      images.push(photo);
    }
    if (logo && logo['content']) {
      logo['type'] = "Logo";
      images.push(logo);
    }
    if (picture && picture['content']) {
      picture['type'] = "Picture";
      images.push(picture);
    }

    if (images.length > 0) {
      upCom["images"] = images;
    }

    if (upCom.area.min > upCom.area.max) {
      toast.error('La superficie mínima no puede ser mayor a la máxima.');
      return;
    }

    if (upCom.budget.min > upCom.budget.max) {
      toast.error('El presupuesto mínimo no puede ser mayor a la máximo.');
      return;
    }

    const response = await api.put<ICompany>(`/v1/companies/${id}`, upCom);

    // const response = await api.put<ICompany>(`/v1/companies/${id}`, transformOnlyId(data));
    if (response?.status === 200) {
      toast.success('Cambios guardados');
    }
  },
  async createCompany({ commit }, company: ICompany): Promise<boolean> {
    const neCom = transformOnlyId({ ...company }) as any;
    neCom['fullName'] = company.name;
    const response = await api.post<IPaginate<IMaster>>(`/v1/companies`, {
      id: uuidv4(), ...neCom
    });

    if (response && response.data) {
      commit('patchCompany', response.data);
    }
    return response?.status === 200;
  },

  async deleteCompany({ commit }): Promise<void> {
    const { status } = await api.delete<IPaginate<IMaster>>(`/v1/company`);
    if (status === 200) {
      commit('deleteCompany');
    }
  },

  async patchProfile({ commit }, user: IUser): Promise<void> {

    const { status } = await api.put<IUser>(`/v1/users`, user);
    if (status === 200) {
      commit('patchProfile', user);
      toast.success('Perfil actualizado con exito');
    }
  },

  async getProfile({ commit }): Promise<IUser> {

    const response = await api.get<IUser>(`/v1/users`);
    if (response?.status === 200) {
      const { company, ...others }: any = response.data;
      commit('patchProfile', others);
      commit('patchCompany', company);
    }
    return response?.data;
  },


};


export default actions;
