import { Options, Vue } from 'vue-class-component';

@Options({
  emits: ['finished'],
  components: {},
  props: {
    msg: String,
    sections: Array,
    vuelidate: undefined,
    showLinePosition: Boolean,
    enableBack: Boolean
  }
})
export default class AccordionVuelidate extends Vue {
  sections = [];
  activeTab = '';
  vuelidate;
  showLinePosition = false;
  enableBack = false;

  mounted(): void {
    this.activeTab = this.sections[0];
  }

  formSection(section: string): string {
    return `form-${section}`;
  }

  infoSection(section: string): string {
    return `info-${section}`;
  }

  titleSection(section: string): string {
    return `title-${section}`;
  }

  async switchTab(tabName: string): Promise<void> {    
    if (this.vuelidate && this.vuelidate[this.activeTab]) {
      const result = await this.vuelidate[this.activeTab].$validate();
      if ( !result) return;
    }
    if (tabName)
      this.activeTab =
        tabName == this.activeTab
          ? this.sections[0]
          : tabName;
    else
      this.$emit('finished', this.vuelidate?.$model);
  }


}
