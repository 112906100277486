import { ICompany } from '@/models/company.model';
import { IPaginate } from '@/models/paginate.model';

export interface CompaniesState {
  isLoading: boolean;
  companies: ICompany[];
  info: IPaginate<ICompany>
  selected: ICompany,
  filter: any,
}

function state(): CompaniesState {
  return {
    isLoading: false,
    companies: [],
    info: undefined,
    selected: undefined,
    filter: undefined
  };
}

export default state;
