import { MutationTree } from 'vuex';
import { ProfileState } from '@/store/profile/state';
import { Project } from '@/models/project.model';
import { IUser } from '@/models/user.model';
import { ICompany } from '@/models/company.model';

const mutation: MutationTree<ProfileState> = {
  setLoading(state: ProfileState, loading: boolean): void {
    state.isLoading = loading;
  },
  setProject(state: ProfileState, projects: Project[]): void {
    state.projects = projects;
  },
  addProject(state: ProfileState, project: Project): void {
    state.projects.push(project);
  },
  deleteProject(state: ProfileState, projectId: string): void {
    state.projects = state.projects.filter(({ id }: Project) => id != projectId);
  },
  patchProject(state: ProfileState, project: Partial<Project>): void {
    state.projects = state.projects.map((_: Project) => {
      if (_.id == project.id)
        return { ..._, ...project } as Project;
      return _;
    });
  },
  setCompany(state: ProfileState, company: ICompany): void {
    state.company = company;
  },
  patchCompany(state: ProfileState, company: ICompany) {
    state.company = company;
  },
  deleteCompany(state: ProfileState): void {
    state.company = undefined;
    state.projects = undefined;
  },
  setProfile(state: ProfileState, profile: IUser): void {
    state.profile = profile;
  },
  patchProfile(state: ProfileState, profile: IUser): void {
    state.profile = { ...state.profile, ...profile };
  },


};


export default mutation;
