import { StateInterface } from '@/store';
import api from '@/store/api';
import { IPaginate } from '@/models/paginate.model';
import IMaster from '@/models/maestre.model';
import { IKeyValue } from '@/models/key-value.model';
import { ConstructionState } from '@/store/utils/constructions/state';
import { ActionTree } from 'vuex';
import filterQuery from '@/helpers/filter-query';
import UrlApi from '@/models/url-api.model';

const url = `v1/${UrlApi.CONSTRUCTIONS_TYPE}`;

const actions: ActionTree<ConstructionState, StateInterface> = {


  async get({ commit }, extra?: IKeyValue): Promise<void> {

    const params = extra
      ? { search: filterQuery(extra) }
      : {};
    const response = await api.get<IPaginate<IMaster>>(url, { params });
    response?.status === 200 && commit('setItems', response.data);
  }

};


export default actions;
