import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, {ProfileState} from "@/store/profile/state";


const profileModule: Module<ProfileState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default profileModule;