const filterQuery = (params: { [t: string]: unknown }) => {
  const filter = [];
  if ( !params || Object.values(params).filter(e => !!e).length === 0) return '';
  Object.keys(params).forEach((key: string) => {
    if (params && !!params[key] )
      filter.push(`${key}:${params[key].toString()}`);
  });
  if ( !filter.length) return '';
  return `[${filter.join(',')}]`;
};


export default filterQuery;
