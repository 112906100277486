import { ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import { CompaniesState } from '@/store/companies/state';
import filterQuery from '@/helpers/filter-query';
import { IPaginate } from '@/models/paginate.model';
import { ICompany } from '@/models/company.model';
import { clearPropertiesEmpty } from '@/helpers/utils';
import transformOnlyIdFilter from '@/helpers/transform-only-id-filter';

const actions: ActionTree<CompaniesState, StateInterface> = {


  async getAll({ commit, state }, payload?: { [t: string]: unknown }): Promise<void> {
    commit('setLoading', true);
    const perPage = 20;
    const { filter, page, order, ..._ }: any = payload;
    const queryParams = _ && filterQuery(_)
      ? { search: filterQuery(_) }
      : {};
    const params = { ...queryParams, page: page ?? 1, perPage };

    // console.log("store>companies>actions>getAll payload:",payload);
    // console.log("store>companies>actions>getAll filter:",filter);

    const newFilter = {};
    if (filter?.[0].employees) {
      newFilter["employees"] = transformOnlyIdFilter(filter[0].employees);
    }
    if (filter?.[0].city) {
      newFilter["provinceName"] = filter[0].city.province;
    }
    if (filter?.[0].provinceName) {
      newFilter["provinceName"] = filter[0].provinceName;
    }
    if (filter?.[0].provinceName?.province) {
      newFilter["provinceName"] = filter[0].provinceName.province;
    }
    if (filter?.[0].area) {
      newFilter["area"] = filter[0].area;
    }
    if (filter?.[0].budget) {
      newFilter["budget"] = filter[0].budget;
    }
    if (filter?.[0].parent) {
      newFilter["parent"] = transformOnlyIdFilter(filter[0].parent);
    }
    if (filter?.[0].activities) {
      newFilter["activities"] = transformOnlyIdFilter(filter[0].activities);
    }
    if (filter?.[0].type) {
      newFilter["type"] = transformOnlyIdFilter(filter[0].type);
    }

    const stringFilter = JSON.stringify([newFilter]);
    params['filter'] = stringFilter;

    // filter && (params['filter'] = JSON.stringify(transformOnlyIdFilter(filter)));

    order && (params['order'] = clearPropertiesEmpty(order));
    // console.log("store>companies>actions>getAll params:",params);

    const response = await api.get<IPaginate<ICompany>>(`/v1/companies`,
      { params });


    const companies = response.data.items.map(comp => {
      if (comp.images?.length > 0) {
        const photo = comp.images.find(img => img.type === "Photo");
        if (photo)
          comp["photo"] = photo;

        const logo = comp.images.find(img => img.type === "Logo");
        if (logo)
          comp["logo"] = logo;

        const picture = comp.images.find(img => img.type === "Picture");
        if (logo)
          comp["picture"] = picture;
      }
      return comp;
    });

    response.data.items = companies;
    if (response?.status === 200)
      payload.page
        ? commit('add', response.data)
        : commit('set', response.data);
    commit('setLoading', false);

  },


  async getBy(state, payload?: { [t: string]: unknown }): Promise<IPaginate<ICompany>> {

    const { filter, page, order, perPage, ..._ }: any = payload;
    const queryParams = _ && filterQuery(_)
      ? { search: filterQuery(_) }
      : {};
    const params = { ...queryParams, page: page ?? 1, perPage };
    filter && (params['filter'] = transformOnlyIdFilter(filter));
    order && (params['order'] = clearPropertiesEmpty(order));

    const response = await api.get<IPaginate<ICompany>>(`/v1/companies`,
      { params });

    return response.status === 200
      ? response.data
      : null;

  },


  async get({ commit }, id: string): Promise<void> {
    commit('setLoading', true);
    const response = await api.get<ICompany>(`/v1/companies/${id}`);
    if (response?.status === 200)
      commit('select', response.data);
    commit('setLoading', false);
  },
  async contact(_, { id, contact }): Promise<boolean> {
    const response = await api.post<ICompany>(`/v1/companies/${id}/contact`, contact);
    return response?.status === 200;
  },
  async saveFilter({ commit }, payload: any): Promise<void> {
    commit('filter', payload);
  },
  async deleteLogo(_, id: string): Promise<boolean> {
    const response = await api.delete<void>(`/v1/companies/${id}/logo`);
    return response?.status === 200;
  },
  async deletePhoto(_, id: string): Promise<boolean> {
    const response = await api.delete<void>(`/v1/companies/${id}/photo`);
    return response?.status === 200;
  },
  async deletePicture(_, id: string): Promise<boolean> {
    const response = await api.delete<void>(`/v1/companies/${id}/picture`);
    return response?.status === 200;
  },

};


export default actions;
