import { Options, Vue } from 'vue-class-component';
import toast from '@/plugins/toast.plugin';


@Options({
  emits: ['delete'],
  props: {
    id: String,
    modelValue: undefined,
    vuelidate: undefined
  }
})
export default class UiInputImg extends Vue {
  id = '';
  modelValue = '';
  vuelidate: unknown;
  idInput = '';

  mounted(): void {
    this.idInput =
      this.id
        ? this.id
        : `img-${new Date().getTime()}`;
  }

  imgChange(e: KeyboardEvent): void {
    const reader = new FileReader();

    const file: File = (<HTMLInputElement>e.target).files[0];
    const imageSize = file.size / Math.pow(1024, 2);

    if (imageSize > 5) {
      toast.error('La imagen excede de 5MB');
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (file) {
        const content: any = reader.result;

        this.$emit('update:modelValue',
          {
            mimetype: file.type,
            content,
            extension: content.substring('data:image/'.length, content.indexOf(';base64'))
          });
      }
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };

  }

  get url(): string {
    if ( !this.modelValue) return '';
    if (typeof this.modelValue == 'object' && this.modelValue['content'])
      return this.modelValue['content'];
    if (typeof this.modelValue == 'object' && this.modelValue['url'])
      return this.modelValue['url'];
    return this.modelValue ?? '';
  }

  removeImg(): void {
    this.$emit('update:modelValue', '');

    if (this.modelValue && this.modelValue['url'])
      this.$emit('delete');
  }
}
