import { GetterTree } from 'vuex';
import { StateInterface } from '@/store';
import IMaster from '@/models/maestre.model';
import { TypeCompanyState } from '@/store/utils/type_company/state';

const getters: GetterTree<TypeCompanyState, StateInterface> = {

  getSelected({ selected }: TypeCompanyState): IMaster {
    return selected;
  },
  getItems({ items }: TypeCompanyState): IMaster[] {
    return items?.items ?? [];
  },
  isLoading({ isLoading }: TypeCompanyState): boolean {
    return isLoading;
  },
};
export default getters;
