import axios from 'axios';
import toast from '@/plugins/toast.plugin';
import store from '@/store/index';
import router from '@/router'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api',
  params: {}
});
let timerError = null;
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }
      config.headers.common['api-language'] = `es`;
    return config;
  }
);

api.interceptors.response.use((response) => {
    return response;
  }, ({ response }) => {
    if (response?.status && +response.status >= 400 && +response.status < 500 && response?.data?.errors) {
      console.log(+response?.data['message'])
      if (typeof response.data.errors == 'string')
        toast.error(response.data.errors);
      else if (Array.isArray(response.data.errors) && response.data.errors.length)
        response.data.errors.forEach(err => toast.error(Object.values(err).join(': ')));
    }
    if (+response?.status === 500) {
      toast.error('¡UPS! Ha ocurrido un error. Por favor intente otra vez');
    }
    if (+response?.status === 401) {
      if (response.data['message'] === "JWT Token not found") {
        toast.error('Ha expirado la sesión')
        router.push({ name: 'sign-in' })
      }

      if (response.data['message'] === "Expired JWT Token") {
        store.dispatch('auth/refreshToken')
          .catch(error => {
            console.log(store.commit("auth/logout"));
            router.push({ name: 'sign-in' })
              .then(() => {
                toast.clear();
                toast.error('Ha expirado la sesión');
              });
          })
      }

      if (response.data['message'] === "User or password are incorrect!") {
        toast.error('El usuario o la contraseña es incorrecta.')
      }

      if (timerError) {
        clearTimeout(timerError);

        timerError = setTimeout(() => {
          toast.error(response.data['message']);
          store.dispatch('auth/onLogout');
        }, 500);
      }
    }
  }
);


export default api;

