import { Options, Vue } from 'vue-class-component';
import UiText from '@/components/form/ui-text/UiText.vue';
import UiCheckBox from '@/components/form/ui-checkbox/UiCheckBox.vue';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';

import UiSelect from '@/components/form/ui-select/UiSelect.vue';
import { minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import CompanyFunction from '@/components/smarts/company/company-function/CompanyFunction.vue';
import CompanyPerformance from '@/components/smarts/company/company-performances/CompanyPerformance.vue';
import { ICompany } from '@/models/company.model';
import UiLoading from '@/components/layout/ui-loading/UiLoading.vue';
import { clearPropertiesEmpty, deletePropertyEqual } from '@/helpers/utils';
import IActivity from '@/models/activity.model';
import activities from '@/store/utils/activities';

@Options({
  components: {
    UiText,
    UiCheckBox,
    UiFormMessage,
    UiSelect,
    CompanyFunction,
    CompanyPerformance,
    UiLoading
  },
  validations: {
    form: {
      activities: { required },
      buildings: { required },
      cities: {
        minLength: minLength(1),
        required,
      },
      area: {
        min: { required },
        max: { required },
      },
      budget: {
        min: { required },
        max: { required },
      },
      employees: { required },
    }
  }
})
export default class MyCompanyActivities extends Vue {
  activeMenu = '';
  store = useStore<StateInterface>();
  v$: any = useVuelidate();
  form: ICompany = null;

  async mounted(): Promise<void> {
    await this.store.dispatch('profile/getProfile');
    this.form = { ...this.company } as ICompany;
    this.store.dispatch('type_company/selected', this.form.type);
  }

  get company(): ICompany {
    return this.store.getters['profile/getCompany'];
  }


  async updateChange(info): Promise<void> {
    const result = await this.v$.$validate();
    // if ( !result) return;
    let sendForm: any = deletePropertyEqual({ newObj: info, oldObj: this.company });
    sendForm['id'] = this.company.id;

    sendForm = clearPropertiesEmpty(sendForm);
    this.company.employees = info.employees;
    this.company.cities = info.cities;
    this.company.activities = info.activities;
    this.company.type = JSON.parse(JSON.stringify(this.company.type)).id;

    if (Object.keys(sendForm).length)
      await this.store.dispatch('profile/patchCompany', this.company);

    this.store.commit('activities/select', null);
  }
}
