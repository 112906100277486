import { Options, Vue } from 'vue-class-component';
import UiDialog from '@/components/layout/ui-dialog/UiDialog.vue';
import UiText from '@/components/form/ui-text/UiText.vue';
import UiCheckBox from '@/components/form/ui-checkbox/UiCheckBox.vue';
import UiTextArea from '@/components/form/ui-textarea/UiTextArea.vue';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';
import UiSelect from '@/components/form/ui-select/UiSelect.vue';
import AccordionVuelidate from '@/components/smarts/accordion-vuelidate/AccordionVuelidate.vue';
import { required } from '@vuelidate/validators';
import IMaster from '@/models/maestre.model';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import UiLoading from '@/components/layout/ui-loading/UiLoading.vue';
import IActivity from '@/models/activity.model';
import ISelectNumber from '@/models/select-number.model';
import UrlApi from '@/models/url-api.model';
import formatNumeric from '@/helpers/format-number';
import { maxSurface, minSurface } from '@/helpers/surfaces';
import { maxBudget, minBudget } from '@/helpers/budget';

@Options({
  components: {
    UiDialog,
    UiText,
    UiCheckBox,
    UiTextArea,
    UiFormMessage,
    UiSelect,
    AccordionVuelidate,
    UiLoading
  },
  props: {
    id: String
  },
  emits: ['onFinished'],
  validations: {
    form: {
      need: {
        type: { required },
        activity: { required },
      },
      city: { required },
      employees: { },
      area: {
        min: { required },
        max: { required },
      },
      budget: {
        min: { required },
      },
    }
  }
})
export default class AdvancedSearchDialog extends Vue {
  v$: any = useVuelidate();
  urlApi = UrlApi;
  store = useStore<StateInterface>();
  form = this.instanceForm;
  isLoading = false;
  surfaceMinimum = minSurface;
  surfaceMaximum = maxSurface;
  budgetMinimum = minBudget;
  budgetMaximum = maxBudget;
  totalCompanies = 0;

  get instanceForm(): unknown {
    return {
      need: {
        type: '',
        activity: '',
        subActivity: ''
      },
      budget: {
        min: 0,
        max: 0
      },
      city: {},
      area: {
        min: 0,
        max: 0
      },
    };
  }

  mounted(): void {
    this.store.dispatch("companies/getAll", {})
      .then(() => {
        this.totalCompanies = this.store.getters['companies/total'];
      });
    this.metaLoad();
  }

  get activities(): IActivity[] {
    return this.store.getters['activities/getItems'];
  }

  get cities(): IActivity[] {
    return this.store.getters['cities/getItems'];
  }


  metaLoad(): void {
    this.store.watch(
      ({ type_company, activities, sub_activities }: StateInterface) => ({
        type_company, activities, sub_activities
      }),
      ({ type_company }) => {
        type_company?.selected && (this.form['need'].type = type_company.selected.id);
      },
      {
        deep: true
      }
    );
  }

  get typeCompany(): IMaster[] {
    return this.store.getters['type_company/getItems'];
  }

  get typeBuildings(): IMaster[] {
    return this.store.getters['buildings/getItems'];
  }

  get employees(): IMaster[] {
    return this.store.getters['employees/getItems'];
  }


  get typeRange(): { min: number, max?: number }[] {
    return [
      { min: 0, max: 50000 },
      { min: 50000, max: 100000 },
      { min: 100000, max: 500000 },
      { min: 500000, max: 1000000 },
      { min: 1000000, max: 5000000 },
      { min: 5000000, max: 10000000 },
      { min: 10000000, max: 20000000 },
      { min: 20000000, max: 50000000 },
      { min: 50000000 },
      { min: 0, max: 0 }
    ];

  }

  async onFinished(): Promise<void> {
    this.isLoading = true;
    setTimeout(() => {
      const { need, ...query }: any = Object.assign({}, this.form);
      const { name, activity, subActivity } = need;
      const payload = { ...query, name };

      payload['type'] = need.type;
      payload['parent'] = activity;
      payload['activities'] = subActivity;
      if (this.form['budget']?.min === 0 && this.form['budget']?.max === 0)
        delete payload['budget'];
      if (this.form['area']?.min === 0 && this.form['area']?.max === 0)
        delete payload['area'];
      this.store.dispatch('companies/saveFilter', payload);
      this.$router.push('/companies');
      document.getElementById('close-form-search').click();
      this.$emit('onFinished');
    }, 2000);
  }

  resetForm(): void {
    this.isLoading = false;
    if (this.$refs.accompany)
      this.$refs.accompany['activeTab'] = this.$refs.accompany['sections'][0];
    this.form = this.instanceForm;
    this.v$.$reset();
  }

  addArray(key: string, item: IMaster): void {
    if ( !this.form[key])
      this.form[key] = [];
    if (this.inArray(key, item)) {
      this.form[key] = this.form[key].filter(e => e.id !== item.id);
    } else
      this.form[key].push(item);
  }

  inArray(key: string, { id }: IMaster): boolean {
    const _arrayList = this.form[key] ?? [];
    return (_arrayList && _arrayList.length && _arrayList.find(e => e.id == id) != null) == true;
  }

  formatBudget(amout: number): string {
    return formatNumeric(amout);
  }

  updateType(type: IMaster): void {
    this.form['need'].type = type.id
    this.store.dispatch('type_company/selected', type);
  }
}
