import {MutationTree} from "vuex";
import {IPaginate} from "@/models/paginate.model";
import IMaster from "@/models/maestre.model";
import {ConstructionState} from "@/store/utils/constructions/state";

const mutation: MutationTree<ConstructionState> = {
    setItems(state: ConstructionState, items: IPaginate<IMaster>) {
        state.items = items;
        state.isLoading = false;
    },
    addItems(state: ConstructionState, {items}: IPaginate<IMaster>) {
        state.items.items = [this.state.items.items, ...items];
        state.isLoading = false;
    },
    setLoading(state: ConstructionState, loading: boolean) {
        state.isLoading = loading;
    },


}


export default mutation;
