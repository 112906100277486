import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StateInterface } from '@/store';
import state, { ProvincesState } from '@/store/utils/provinces/state';


const provincesModule: Module<ProvincesState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default provincesModule;
