export enum UrlApi {
  ACTIVITIES = 'activities',
  BUILDINGS='buildings',
  CITIES='cities',
  PROVINCES='provinces',
  EMPLOYEES='employees',
  COMPANY_TYPE='companytypes',
  CONSTRUCTIONS_TYPE='constructiontypes',
}

export default UrlApi;

