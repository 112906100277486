import _ from 'lodash';

export const clearPropertiesEmpty = (objValue: any): any => {
  const newObj = { ...objValue };
  if(typeof objValue !== 'object') return objValue;
  Object.keys(newObj).forEach((k) => {
    if (Array.isArray(newObj[k]) && newObj[k].length == 0)
      delete newObj[k];
    else if (typeof newObj[k] === 'object') {
      newObj[k] = clearPropertiesEmpty(newObj[k]);
      if (Object.values(newObj[k]).filter(e => !!e).length == 0)
        delete newObj[k];
    }
    (typeof newObj[k] !== 'boolean' && !newObj[k]) && delete newObj[k];
  });

  return newObj;
};


export const deletePropertyEqual = ({ newObj, oldObj = {} }): { newObj, oldObj } => {
  return _.reduce(newObj, (result, value, key) => {
    if ( !_.isEqual(value, oldObj[key])) {
      result[key] =
        !Array.isArray(value) && typeof value === 'object'
          ? deletePropertyEqual({ newObj: value, oldObj: oldObj[key] })
          : value;
    }
    return result;
  }, {});
};
