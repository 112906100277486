export const validateEqual = (value: unknown, value2: unknown): boolean => {
  return value && value2 && value === value2;
};
export const validateRegexEmail = (value: string): boolean => {
  const regex = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g;
  return regex.test(value);
};

export const validateRegexUrl = (value: string): boolean => {
  const regex = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi;
  return regex.test(value);
};
