import { IPaginate } from '@/models/paginate.model';
import IActivity from '@/models/activity.model';

export interface SubActivitiesState {
  isLoading: boolean;
  items: IPaginate<IActivity>,
  selected: IActivity
}

function state(): SubActivitiesState {
  return {
    isLoading: false,
    items: undefined,
    selected: undefined,
  };
}

export default state;
