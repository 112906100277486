import { Options, Vue } from 'vue-class-component';
import CompanyData from '@/components/smarts/company/company-data/CompanyData.vue';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import { email, minLength, required } from '@vuelidate/validators';
import CompanyContact from '@/components/smarts/company/company-contact/CompanyContact.vue';
import CompanyFunction from '@/components/smarts/company/company-function/CompanyFunction.vue';
import CompanyPerformance from '@/components/smarts/company/company-performances/CompanyPerformance.vue';
import AccordionVuelidate from '@/components/smarts/accordion-vuelidate/AccordionVuelidate.vue';
import { ICompany, IContact } from '@/models/company.model';
import isDev from '@/helpers/is-dev';
import { IAddress } from '@/models/address.model';
import IActivity from '@/models/activity.model';
import { IUser } from '@/models/user.model';
import toast from '@/plugins/toast.plugin';
import faker from '@faker-js/faker';

@Options({
  components: {
    AccordionVuelidate,
    CompanyData,
    CompanyContact,
    CompanyFunction,
    CompanyPerformance
  },
  validations: {
    form: {
      company: {
        name: { required },
        type: { required },
        cif: { required },
        address: {
          address:{required},
          city:{required},
          province:{required},
          zipcode:{required},
        }
      },
      contact: {
        email: { required, email },
        phone: { required },
      },
      function: {
        activities: { required },
        area: {
          min: { required },
          max: { required },
        },
        budget: {
          min: { required },
          max: { required },
        },
        employees: { required }
      },
      cities: {
        minLength: minLength(1),
        required,
      },
    }
  }
})
export default class Register02 extends Vue {

  form = {
    company: {
      name: '',
      type: '',
      cif: '',
      address: {
        address: '',
        country: 'Spain'
      },
      description: '',
      images: []
    } as ICompany,
    contact: {
      phone: '',
      email: '',
    } as IContact,
    function: {
      area: { min: 0, max: 0 },
      budget: { min: 0, max: 0 },
      constructions: []
    },
    cities: []
  };
  v$: any = useVuelidate();
  store = useStore<StateInterface>();
  activities: IActivity[] = [];
  sub_activities: IActivity[] = [];

  async mounted(): Promise<void> {
    const company: ICompany = await this.store.getters['profile/getCompany'] ?? {};
    if (isDev) {
      // company.name = faker.company.companySuffix();
      company.cif = faker.random.alphaNumeric(10);
      company.description = JSON.stringify({ ...company, description: 'this' });
      company.address = {} as IAddress;
      company.address['address'] = faker.address.direction();
      company.address['city'] = 'Barcelona';
      company.address['province'] = 'Barcelona';
      company.address['zipcode'] = '8035';
    }
    company.type = '';
    company.address = {} as IAddress;
    company.address['city'] = 'A Baña';
    company.address['province'] = 'A Coruña';
    company.address['zipcode'] = '15839';
    this.form.company = { ...company, address: { ...company.address, country: 'Spain' } } as ICompany;
    this.form.contact = {
      email: this.profile?.email ?? '',
      phone: isDev
        ? faker.phone.phoneNumber()
        : '',
    } as IContact;
    this.form.function =
      {
        constructions: [],
        area: { min: company.area?.min ?? 0, max: company.area?.max ?? 0 },
        budget: { min: company.budget?.min ?? 0, max: company.budget?.max ?? 0 }
      };
  }

  async onFinished(_): Promise<void> {
    const result = await this.v$.$validate();
    if ( !result) return;
    const { networks, ...contact }: any = this.form.contact;

    const companyForm = this.form.company;
    companyForm.description = companyForm.description?.substring(0,800);

    const photo = companyForm['photo'];
    const logo = companyForm['logo'];
    const picture = companyForm['picture'];

    delete companyForm['photo'];
    delete companyForm['logo'];
    delete companyForm['picture'];

    const newCompany = {
      ...companyForm, contact, ...this.form.function, cities: this.form.cities, networks
    };
    newCompany.cnae = '057f6fdd-9cc9-44bb-bf0c-1f920a172798';
    newCompany['buildings'] = [];



    const images = [];
    if (photo) {
      photo['type'] = "Photo";
      images.push(photo);
    }
    if (logo) {
      logo['type'] = "Logo";
      images.push(logo);
    }
    if (picture) {
      picture['type'] = "Picture";
      images.push(picture);
    }

    if (images.length > 0) {
      newCompany["images"] = images;
    }

    if (this.isLogged) {
      const response = await this.store.dispatch('profile/createCompany', newCompany);
      if (response) {
        toast.success('Compañia registrada con exito');
        await this.$router.push('/');

      }
    } else {
      await this.store.commit('profile/patchCompany', newCompany);
      await this.$router.push('/rwc/03');
    }
  }


  get isLogged(): boolean {
    return this.store.getters['auth/isUserLogin'];
  }

  get profile(): IUser {
    return this.store.getters['auth/user'];
  }

  getType(type: string): string {
    return this.store.getters['type_company/getItems'].find(({ id }) => type === id).name ?? '';
  }

}
