import { GetterTree } from 'vuex';
import { StateInterface } from '@/store';
import { ProvincesState } from '@/store/utils/provinces/state';
import { IAddress } from '@/models/address.model';

const getters: GetterTree<ProvincesState, StateInterface> = {
  getPage({ items }: ProvincesState): number {
    return items.page ?? 1;
  },
  getTotal({ items }: ProvincesState): number {
    return items.total ?? 0;
  },
  getItems({ items }: ProvincesState): IAddress[] {
    return items?.items ?? [];
  },
  isLoading({ isLoading }: ProvincesState): boolean {
    return isLoading;
  },
};
export default getters;
