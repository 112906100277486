import { ActionTree } from 'vuex';
import { AuthState } from '@/store/auth/state';
import { StateInterface } from '@/store';
import { IToken } from '@/models/token.model';
import api from '@/store/api';
import jwtDecode from 'jwt-decode';
import { IUser } from '@/models/user.model';
import { v4 as uuidv4 } from 'uuid';
import router from '@/router';

const actions: ActionTree<AuthState, StateInterface> = {


  async isLogin({ commit, state }): Promise<void> {
    if (state.token?.access)
      return;

    const access = localStorage.getItem('token');
    const refresh = localStorage.getItem('refresh');
    if ( !access) return;
    if ( !jwtDecode(access)) {
      commit('logout');
      return;
    }
    commit('profile/setProfile', jwtDecode(access)['user'], { root: true });
    commit('setToken', { access, refresh });

  },

  async changePass(_, payload: { actual: string, new: string }): Promise<boolean> {
    const response = await api.put<IToken>(`/v1/users/password`, payload);
    return response?.status === 200;
  },
  async login({ commit }, oauth: { email: string, password: string }): Promise<void> {
    const respone = await api.post<{ token: string; refresh_token: string }>(`/v1/auth/login`, oauth);
    if (respone?.data) {
      const { data, status } = respone;
      const { access, refresh }: IToken = { access: data.token, refresh: data.refresh_token };

      if (status === 200 && access && refresh) {
        await commit('setToken', { access, refresh });
      }
    }

  },
  async register({ commit }, user: Partial<IUser>): Promise<boolean> {
    const body: any = { id: uuidv4(), ...user };
    delete body.confirmPass;
    const response: any = await api.post<IToken>(`/v1/users`, body);


    if (response?.status === 200) {
      const { data } = response;
      const { access, refresh }: IToken = { access: data.token, refresh: data.refresh_token };
      await commit('setToken', { access, refresh });

      return true;
    }
    commit('logout');
    return false;

  },
  async passwordUpdate(_, payload: { token: string, 'password': string }): Promise<boolean> {

    const response = await api.post<IToken>(`/v1/auth/password/update`, payload);
    return response?.status === 200;

  },
  async recoveryPwd(_, email): Promise<boolean> {

    const response = await api.post<IToken>(`/v1/auth/password/reset`, { email });
    return response?.status === 200;

  },
  async onLogout({ commit }): Promise<void> {
    commit('logout');
  },

  async refreshToken({ commit, getters }): Promise<void> {
    const refresh = localStorage.getItem('refresh');
    const { status, data } = await api.post<any>(`/v1/auth/token/refresh`, { refresh_token: refresh });
    if (status === 200) {
      const newToken: IToken = {
        access: data.token,
        refresh: data.refresh_token,
      }
      commit('setToken', newToken);

      router.go(0);
    }
  }

};


export default actions;
