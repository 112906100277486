import ISelectNumber from "@/models/select-number.model";

export const minSurface: ISelectNumber[] = [
  {
    name:'0',
    value: 0
  }, {
    name:'50',
    value: 50
  }, {
    name:'100',
    value: 100
  }, {
    name:'250',
    value: 250
  }, {
    name:'500',
    value: 500
  }, {
    name:'1.000',
    value: 1000
  }, {
    name:'5.000',
    value: 5000
  }, {
    name:'10.000',
    value: 10000
  }, {
    name:'20.000',
    value: 20000
  }
];

export const maxSurface: ISelectNumber[] = [
  {
    name:'50',
    value: 50
  }, {
    name:'100',
    value: 100
  }, {
    name:'250',
    value: 250
  }, {
    name:'500',
    value: 500
  }, {
    name:'1.000',
    value: 1000
  }, {
    name:'5.000',
    value: 5000
  }, {
    name:'10.000',
    value: 10000
  }, {
    name:'20.000',
    value: 20000
  }, {
    name:'Más de 20.000',
    value: 20001
  }
];
