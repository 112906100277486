import { MutationTree } from 'vuex';
import { AuthState } from '@/store/auth/state';
import { IToken } from '@/models/token.model';
import jwtDecode from 'jwt-decode';
import { IUser } from '@/models/user.model';


const mutation: MutationTree<AuthState> = {
  setToken(state: AuthState, token: IToken): void {
    state.token = token;
    localStorage.setItem('token', token.access);
    localStorage.setItem('refresh', token.refresh);

    if (jwtDecode(token.access)) {

      const { email, roles, id } = jwtDecode(token.access) as IUser;
      state.user = { email, roles, id };
    }
  },
  setLoading(state: AuthState, loading: boolean): void {
    state.isLoading = loading;
  },

  logout(state: AuthState): void {
    state.isLoading = false;
    state.user = undefined;
    state.token = undefined;
    localStorage.clear();
    state.user = null;
  },

};


export default mutation;
