export const transformOnlyIdFilter = (filter: any) => {
  if (filter == null) return;
  if (Array.isArray(filter)) {
    return filter.filter(e => !!e).map(obj => transformOnlyIdFilter(obj));
  }
  if (typeof filter === 'object' && filter?.id) {
    return filter.id;
  }
  if (typeof filter !== 'object')
    return filter;

  const newObj = {};

  Object.keys(filter).forEach((key: string) => {
    newObj[key] = transformOnlyIdFilter(filter[key]);
  });

  return newObj;

};

export default transformOnlyIdFilter;
