import {MutationTree} from "vuex";
import {IPaginate} from "@/models/paginate.model";
import {CitiesState} from "@/store/utils/cities/state";
import {IAddress} from "@/models/address.model";
import _ from "lodash";

const mutation: MutationTree<CitiesState> = {
    setItems(state: CitiesState, items: IPaginate<IAddress>) {
        state.items = items;
        state.isLoading = false;
        if (items.items?.length) {

            state.provinces = _.chain(items.items).groupBy("province").map((province, cities) => ({
                province, cities
            })).value();
        }
    },
    addItems(state: CitiesState, {items}: IPaginate<IAddress>) {
        state.items.items = [this.state.items.items, ...items];
        state.isLoading = false;
    },
    setLoading(state: CitiesState, loading: boolean) {
        state.isLoading = loading;
    },


}


export default mutation;
