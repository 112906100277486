import { ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import { IPaginate } from '@/models/paginate.model';
import { IKeyValue } from '@/models/key-value.model';
import { SubActivitiesState } from '@/store/utils/sub_activities/state';
import filterQuery from '@/helpers/filter-query';
import IActivity from '@/models/activity.model';
import UrlApi from '@/models/url-api.model';
import IMaster from '@/models/maestre.model';

const url = `v1/${UrlApi.ACTIVITIES}`;

const actions: ActionTree<SubActivitiesState, StateInterface> = {


  async get({ commit }, extra?: IKeyValue): Promise<void> {

    const params = extra
      ? { search: filterQuery(extra) }
      : {};
    params['perPage'] = 200;
    const response = await api.get<IPaginate<IMaster>>(url, { params });
    response?.status === 200 && commit('setItems', response.data);
  },

  select({ commit }, payload?: IActivity): void {
    commit('select', payload);
  }

};


export default actions;
