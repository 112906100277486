import { GetterTree } from 'vuex';
import { StateInterface } from '@/store';
import { CompaniesState } from '@/store/companies/state';
import { ICompany } from '@/models/company.model';

const getters: GetterTree<CompaniesState, StateInterface> = {
  items({ companies }: CompaniesState): ICompany[] {
    return companies;
  },
  nextPage({ info }: CompaniesState): number {
    return info?.nextPage;
  },
  backPage({ info }: CompaniesState): number {
    return info.lastPage;
  },
  pages({ info }: CompaniesState): number {
    return info.pages ?? 1;
  },
  total({ info }: CompaniesState): number {
    return info?.total ?? 0;
  },
  selected({ selected }: CompaniesState): ICompany {
    const company = selected;
    if (company?.contact.website)
      company.contact.website = checkExternalUrl(company?.contact.website);
    if (company?.networks.length > 0) {
      company.networks.forEach(element => {      
        element.url = checkExternalUrl(element.url);
      });
    }
    return company;
  },
  isLoading({ isLoading }: CompaniesState): boolean {
    return isLoading;
  },
  filterActive({ filter }: CompaniesState): any {

    return filter;
  },
};

function checkExternalUrl(url: string): string {
  const scheme = url.substring(0,5);
  if (scheme === "https")
    return url;
  return "https://"+url;
}
export default getters;
