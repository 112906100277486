import {Options, Vue} from 'vue-class-component';


@Options({
    props: {
        isShow: Boolean,
        message: String,
        vuelidate: undefined,
    }
})
export default class UiFormMessage extends Vue {
    isShow = false;
    message = '';
    vuelidate: undefined;

}
