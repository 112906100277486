import { ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import { IPaginate } from '@/models/paginate.model';
import IMaster from '@/models/maestre.model';
import { IKeyValue } from '@/models/key-value.model';
import { EmployeesState } from '@/store/utils/employees/state';
import filterQuery from '@/helpers/filter-query';
import UrlApi from '@/models/url-api.model';

const url = `v1/${UrlApi.EMPLOYEES}`;

const actions: ActionTree<EmployeesState, StateInterface> = {


  async get({ commit }, extra?: IKeyValue): Promise<void> {

    const params = extra
      ? { search: filterQuery(extra) }
      : {};
    const response = await api.get<IPaginate<{ label: string; id: string }>>(url, { params });
    if (response?.status === 200) {
      const _aux: IPaginate<IMaster> = {
        ...response.data, items: response.data.items.map(e => {
          return { id: e.id, name: e.label };
        })
      };
      commit('setItems', _aux);
    }
  }

};


export default actions;
