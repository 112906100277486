import { ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import { IPaginate } from '@/models/paginate.model';
import { IKeyValue } from '@/models/key-value.model';
import { CitiesState } from '@/store/utils/cities/state';
import { IAddress } from '@/models/address.model';
import filterQuery from '@/helpers/filter-query';
import UrlApi from '@/models/url-api.model';
import IMaster from '@/models/maestre.model';

const url = `v1/${UrlApi.CITIES}`;

const actions: ActionTree<CitiesState, StateInterface> = {


  async get({ commit }, extra?: IKeyValue): Promise<void> {

    const params = extra
      ? { search: filterQuery(extra) }
      : {};

    const response = await api.get<IPaginate<IMaster>>(url, { params });
    response?.status === 200 && commit('setItems', response.data);
  },
  async getCityBy({ commit }, extra?: IKeyValue): Promise<IAddress[]> {

    const params = extra
      ? { search: filterQuery(extra) }
      : {};
    const { data, status } = await api.get<IPaginate<IAddress>>(url, { params });
    if (status == 200 && data?.items?.length)
      return data.items;
    return [];
  }

};


export default actions;
