import { MutationTree } from 'vuex';
import { IPaginate } from '@/models/paginate.model';
import IMaster from '@/models/maestre.model';
import { SubActivitiesState } from '@/store/utils/sub_activities/state';
import IActivity from '@/models/activity.model';

const mutation: MutationTree<SubActivitiesState> = {
  setItems(state: SubActivitiesState, items: IPaginate<IActivity>) {
    state.items = items;
    state.isLoading = false;
  },
  addItems(state: SubActivitiesState, { items }: IPaginate<IMaster>) {
    state.items.items = [this.state.items.items, ...items];
    state.isLoading = false;
  },
  setLoading(state: SubActivitiesState, loading: boolean) {
    state.isLoading = loading;
  },
  select(state: SubActivitiesState, subActivity: IActivity) {
    localStorage.setItem('subActivity', JSON.stringify(subActivity));
    state.selected = subActivity;
  },
};


export default mutation;
