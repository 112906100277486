import { Options, Vue } from 'vue-class-component';
import UiText from '@/components/form/ui-text/UiText.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import useVuelidate from '@vuelidate/core';
import IMaster from '@/models/maestre.model';
import UiCheckBox from '@/components/form/ui-checkbox/UiCheckBox.vue';
import { email, required } from '@vuelidate/validators';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';
import * as _ from 'lodash';
import CompanyData from '@/components/smarts/company/company-data/CompanyData.vue';
import CompanyContact from '@/components/smarts/company/company-contact/CompanyContact.vue';
import { ICompany } from '@/models/company.model';
import UiLoading from '@/components/layout/ui-loading/UiLoading.vue';
import { clearPropertiesEmpty, deletePropertyEqual } from '@/helpers/utils';

@Options({
  components: {
    UiText,
    UiCheckBox,
    UiFormMessage,
    CompanyData,
    CompanyContact,
    UiLoading
  },
  validations: {
    form: {
      name: { required },
      type: { required },
      contact: {
        email: { required, email },
        phone: { required },
      },
      cif: { required },
      address: {
        city: { required },
        province: { required },
        country: { required },
        address: { required },
        zipcode: { required },
      },
    }
  }
})
export default class MyCompany extends Vue {
  store = useStore<StateInterface>();
  v$: any = useVuelidate();
  form: any = {};
  activeMenu = '';

  async mounted(): Promise<void> {

    await this.store.dispatch('profile/getProfile');
    this.instanceForm();
  }

  instanceForm(): void {
    this.form = { ...this.company, contact: { ...this.company.contact, networks: this.company.networks ?? [] } };
    this.form.type = this.form.type.id;
    const logo = this.company.images?.find(img => img.type === "Logo");
    const photo = this.company.images?.find(img => img.type === "Photo");
    const picture = this.company.images?.find(img => img.type === "Picture");

    if(logo) {
      this.form["logo"] = logo;
    }

    if(photo) {
      this.form["photo"] = photo;
    }

    if(picture) {
      this.form["picture"] = picture;
    }
  }

  get typeCompany(): IMaster[] {
    return this.store.getters['type_company/getItems'];
  }

  get company(): ICompany {
    return this.store.getters['profile/getCompany'];
  }

  async updateChange(info: Partial<ICompany>): Promise<void> {
    const result = await this.v$.$validate();
    if ( !result) return;
    // await this.store.dispatch('profile/getProfile');

    const networks = [
      ...(info && info.contact['networks']
        ? info.contact['networks']
        : [])
    ];
    const auxSend = { ...info };
    delete auxSend['contact']['networks'];
    let sendForm = deletePropertyEqual({
      newObj: { ...auxSend, networks, images: this.company.images },
      oldObj: this.company
    });
    sendForm = clearPropertiesEmpty(sendForm);
    sendForm['id'] = this.company.id;
    if (auxSend?.images && auxSend?.images['content']) {
      sendForm['images'] = auxSend.images;
      this.company['images'] = auxSend.images;
    }

    this.company.type = info.type;
    this.company.name = info.name;
    this.company.cif = info.cif;
    this.company.description = info.description;
    this.company.contact.email = info.contact.email;
    this.company.contact.phone = info.contact.phone;
    this.company['networks'] = networks;

    const photo = info['photo'];
    const logo = info['logo'];
    const picture = info['picture'];

    const images = [];
    if (photo && photo['content']) {
      photo['type'] = "Photo";
      images.push(photo);
    }
    if (logo && logo['content']) {
      logo['type'] = "Logo";
      images.push(logo);
    }
    if (picture && picture['content']) {
      picture['type'] = "Picture";
      images.push(picture);
    }

    this.company['images'] = images;

    if (sendForm && Object.values(sendForm).length) {
      await this.store.dispatch('profile/patchCompany', this.company);
      await this.store.dispatch('profile/getProfile');
      this.instanceForm();
    }

    this.store.commit('activities/select', null);
  }

  async deleteLogo(): Promise<void> {
    await this.store.dispatch('companies/deleteLogo', this.company.id);
    await this.store.dispatch('profile/getProfile');
  }

  async deletePhoto(): Promise<void> {
    await this.store.dispatch('companies/deletePhoto', this.company.id);
    await this.store.dispatch('profile/getProfile');
  }

  async deletePicture(): Promise<void> {
    await this.store.dispatch('companies/deletePicture', this.company.id);
    await this.store.dispatch('profile/getProfile');
  }

}
