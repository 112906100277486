import {Options, Vue} from 'vue-class-component';
import AreaAmountCompany from "@/components/smarts/company/area-company/AreaAmountCompany.vue";

@Options({
    components: {
        AreaAmountCompany
    },
    props: {
        header: String,
        description: String,
        tags: Array,
        logo: String,
        picture: String,
    }
})
export default class CardProject extends Vue {
    header = '';
    description!: string;
    tags: string[] = [];
    logo?: string;
    picture!: string;
}
