import { GetterTree } from 'vuex';
import { StateInterface } from '@/store';
import { HelperState } from '@/store/utils/helper/state';

const getters: GetterTree<HelperState, StateInterface> = {
  isLoading({ isLoading }: HelperState): boolean {
    return isLoading;
  },
};
export default getters;
