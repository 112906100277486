import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, { HelperState } from '@/store/utils/helper/state';


const helperModule: Module<HelperState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default helperModule;
