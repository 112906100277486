import {Project} from "@/models/project.model";
import {IUser} from "@/models/user.model";
import { ICompany } from '@/models/company.model';

export interface ProfileState {
    isLoading: boolean;
    projects: Project[];
    profile: IUser;
    company: ICompany;
}

function state(): ProfileState {
    return {
        isLoading: false,
        projects: [],
        profile: undefined,
        company: undefined
    }
}

export default state;
