export interface HelperState {
  isLoading: boolean;
}

function state(): HelperState {
  return {
    isLoading: false,
  };
}

export default state;
