import { Options, Vue } from 'vue-class-component';
import UiText from '@/components/form/ui-text/UiText.vue';
import UiCheckBox from '@/components/form/ui-checkbox/UiCheckBox.vue';
import UiTextArea from '@/components/form/ui-textarea/UiTextArea.vue';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';
import UiInputImg from '@/components/form/ui-input-img/UiInputImg.vue';
import IMaster from '@/models/maestre.model';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import UiSelect from '@/components/form/ui-select/UiSelect.vue';
import { IAddress } from '@/models/address.model';
import UrlApi from '@/models/url-api.model';

@Options({
  emits: ['deleteImg'],
  components: {
    UiText,
    UiCheckBox,
    UiTextArea,
    UiFormMessage,
    UiInputImg,
    UiSelect
  },
  props: {
    form: Object,
    txtButton: String,
    vuelidate: undefined
  },
})
export default class CompanyData extends Vue {
  urlApi = UrlApi;
  store = useStore<StateInterface>();
  form = {
    type: '',
    address: {
      address: '',
      zip: '',
      city: '',
      province: '',
      country: 'Spain'
    }
  };
  txtButton = '';
  vuelidate;

  provinces: IAddress[] = [];
  cities: IAddress[] = [];
  zipCode: IAddress[] = [];
  countries: IAddress[] = [];

  get typeCompany(): IMaster[] {
    return this.store.getters['type_company/getItems'];
  }

  async updateChange(): Promise<void> {
    this.$emit('update:form', this.vuelidate?.$model);
  }

  async deleteLogo(): Promise<void> {
    this.$emit('deleteLogo', this.vuelidate?.$model);
  }

  async deletePhoto(): Promise<void> {
    this.$emit('deletePhoto', this.vuelidate?.$model);
  }

  async deletePicture(): Promise<void> {
    this.$emit('deletePicture', this.vuelidate?.$model);
  }
}
