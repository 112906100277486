import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, {ConstructionState} from "@/store/utils/constructions/state";


const constructionsModule: Module<ConstructionState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default constructionsModule;
