import {Options, Vue} from "vue-class-component";
import UiAccordion from "@/components/layout/ui-accordion/UiAccordion.vue";

@Options({
    components: {UiAccordion},
})
export default class FAQs extends Vue {
    questions: { header: string; body: string }[] = [
        {
            header: '¿Para qué necesito registrarme en Triente?',
            body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi commodi ea, enim impedit inventore minima officia pariatur porro quis reprehenderit tempora voluptatibus? Animi aperiam facilis sequi sit veniam voluptate voluptatibus!'
        },
        {
            header: '¿Qué necesito para dar de alta mi empresa?',
            body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi commodi ea, enim impedit inventore minima officia pariatur porro quis reprehenderit tempora voluptatibus? Animi aperiam facilis sequi sit veniam voluptate voluptatibus!'
        },
        {
            header: '¿Cómo voy a saber si hay alguien interesado en mi perfil?',
            body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi commodi ea, enim impedit inventore minima officia pariatur porro quis reprehenderit tempora voluptatibus? Animi aperiam facilis sequi sit veniam voluptate voluptatibus!'
        },
        {
            header: '¿Qué coste tiene Triente?',
            body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi commodi ea, enim impedit inventore minima officia pariatur porro quis reprehenderit tempora voluptatibus? Animi aperiam facilis sequi sit veniam voluptate voluptatibus!'
        },
    ];
}
