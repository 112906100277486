import {Options, Vue} from 'vue-class-component';


@Options({
    props: {
        id: String,
        label: String,
        placeholder: String,
        modelValue: undefined,
        vuelidate: undefined
    }
})
export default class UiTextArea extends Vue {
    label = '';
    id = '';
    placeholder = '';
    modelValue = '';
    vuelidate: unknown;

    get idInput(): string {
        return this.id ? this.id : this.label.split(' ').join('-')
    }

    updatedEmit(v): void {
        this.$emit('update:modelValue', v);

    }
}
