import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    id: String,
    label: String,
    isChecked: Boolean,
    vuelidate: undefined,
  }
})
export default class UiCheckBox extends Vue {
  label = '';
  id = '';
  isChecked = false;

  get idInput(): string {
      return this.id ? this.id : this.label.split(' ').join('-')
  }
}
