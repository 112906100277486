import {IUser} from "@/models/user.model";
import {IToken} from "@/models/token.model";

export interface AuthState {
    isLoading: boolean;
    user: IUser;
    token: IToken,
}

function state(): AuthState {
    return {
        isLoading: true,
        user: undefined,
        token: undefined,
    }
}

export default state;