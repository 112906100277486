import { Options, Vue } from 'vue-class-component';
import BannerNavigation from '@/components/smarts/ui-nav-bar/banner-navigation/BannerNavigation.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import AdvancedSearchDialog from '@/components/smarts/dialog-advanced-search/DialogAdvancedSearch.vue';
import ExploreCompanies from '@/components/smarts/ui-nav-bar/explore-companies/ExploreCompanies.vue';

export enum MENU_MORE {
  COMPANIES = 'Explorar empresas',
  PROFILE = 'Mi Perfil'
}

@Options({
  components: {
    BannerNavigation,
    AdvancedSearchDialog,
    ExploreCompanies
  },
  props: {
    msg: String
  }
})
export default class NavBar extends Vue {
  store = useStore<StateInterface>();

  msg!: string;
  moreOpen: MENU_MORE = null;
  menuMore = MENU_MORE;
  activeLeave = false;
  navOpen = false;

  get isLogged(): boolean {
    return this.store.getters['auth/isUserLogin'];
  }

  created(): void {
    window.addEventListener('scroll', (_:Event) => this.handleScroll());
  }

  handleScroll(): void {
    if (window.scrollY > 50) {
      document.getElementById('nav-bar')?.classList.add('fixed-top');
    } else {
      document.getElementById('nav-bar')?.classList.remove('fixed-top');
    }
  }

  openMobileNavBar(): void {
    this.navOpen = !this.navOpen;
    this.moreOpen = null
    document.body.addEventListener("click", this.closeNavBarIfParentIsNotNavBar)
  }

  openOption(option: MENU_MORE): void {
    this.navOpen = false;
    this.moreOpen =
      option && option == this.moreOpen
        ? null
        : option;

    if (this.moreOpen) {
      setTimeout(() => {
        document.body.addEventListener("click", this.closeNavBarIfParentIsNotNavBar)
      }, 500)
    }
  }

  closeNavBarIfParentIsNotNavBar(event): void {
    const el = event.target as HTMLElement;
    if (!el.closest(".nav-megamenu") && !el.closest("#nav-bar")) {
      this.closeNavBar();
      document.body.removeEventListener("click", this.closeNavBarIfParentIsNotNavBar);
    }
  }

  closeNavBar(): void {
    this.navOpen = false;
    this.moreOpen = null;
  }

  get menuProfile(): [] {
    return this.store.getters['profile/getMenuProfile'] ?? [];
  }

  async onLogout(): Promise<void> {
    await this.store.commit('auth/logout');
    await this.$router.push('/');
  }
}
