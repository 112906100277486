import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/home/HomeView.vue';
import MyProfile from '@/views/my-profile/MyProfile.vue';
import MyProfileMain from '@/views/my-profile/main/ProfileMain.vue';
import MyCompany from '@/views/my-profile/company/MyCompany.vue';
import MyCompanyActivities from '@/views/my-profile/company-activities/CompanyActivities.vue';
import MyProfileNotifications from '@/views/my-profile/notifications/ProfileNotifications.vue';
import RegisterWithCompany from '@/views/register-with-company/RegisterWithCompany.vue';
import Register01 from '@/views/register-with-company/register-01/Register01.vue';
import Register02 from '@/views/register-with-company/register-02/Register02.vue';
import Register03 from '@/views/register-with-company/register-03/Register03.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/companies',
    name: 'results',
    component: () => import( '@/views/results-catalogs/ResultsCatalogView.vue'), meta: { transition: 'slide-left' },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '@/views/auth/register/RegisterView.vue')
  },
  {
    path: '/know-me',
    name: 'know-me',
    component: () => import( '@/views/know-me/KnowMeView.vue')
  },
  {
    path: '/comenzar',
    name: 'comenzar',
    component: () => import( '@/views/comenzar/ComenzarView.vue')
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import( '@/views/auth/sign-in/SignInView.vue')
  },
  {
    path: '/company/:id',
    name: 'company-detail',
    props: true,
    component: () => import( '@/views/company-detail/CompanyDetailView.vue')
  },
  {
    path: '/users/account/recovery/:token',
    name: 'company',
    props: true,
    component: () => import( '@/views/auth/reset-pwd/ResetPwdView.vue')
  },
  {
    path: '/my-profile',
    name: 'profile',
    component: MyProfile,
    children: [
      {
        path: '',
        component: MyProfileMain
      },
      {
        path: 'company',
        component: MyCompany
      },
      {
        path: 'activities',
        component: MyCompanyActivities
      },
      {
        path: 'notifications',
        component: MyProfileNotifications
      },
    ]
  },
  {
    path: '/rwc',
    name: 'rwc',
    component: RegisterWithCompany,
    children: [
      {
        path: '01',
        component: Register01
      },
      {
        path: '02',
        component: Register02
      },
      {
        path: '03',
        component: Register03
      },
      {
        path: '', redirect: '/rwc/01'
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
});

export default router;
