import {GetterTree} from "vuex";
import {StateInterface} from "@/store";
import {ProfileState} from "@/store/profile/state";
import {Project} from "@/models/project.model";
import {IUser} from "@/models/user.model";
import { ICompany } from '@/models/company.model';

const getters: GetterTree<ProfileState, StateInterface> = {
    getProjects({projects}: ProfileState): Project[] {
        return projects ?? [];
    },
    getCompany({company}: ProfileState): ICompany {
        return company;
    },
    getProfile({profile}: ProfileState): IUser {
        return profile;
    },
    getMenuProfile(state: ProfileState): { label: string, link: string, icon?: string }[] {
        const retorno = [];
        const comapnyValid = !!state.company?.id;
        if (comapnyValid)
            retorno.push({label: 'Datos de empresa', link: '/my-profile/company', icon: 'icon-fi_bell'});
        if (comapnyValid)
            retorno.push({label: 'Actividad', link: '/my-profile/activities', icon: 'icon-fi_file-text'});
        retorno.push({label: 'Datos administrador', link: '/my-profile', icon: 'icon-fi_user'});
        /*
        if (comapnyValid)
            retorno.push({label: 'Notificaciones', link: '/my-profile/notifications', icon: 'icon-fi_bell'});
        */
        return retorno;

    }
}
export default getters;
