import { GetterTree } from 'vuex';
import { StateInterface } from '@/store';
import { SubActivitiesState } from '@/store/utils/sub_activities/state';
import IActivity from '@/models/activity.model';

const getters: GetterTree<SubActivitiesState, StateInterface> = {
  getPage({ items }: SubActivitiesState): number {
    return items.page ?? 1;
  },
  getTotal({ items }: SubActivitiesState): number {
    return items.total ?? 0;
  },
  getItems({ items }: SubActivitiesState): IActivity[] {
    return items?.items ?? [];
  },
  getSelected: function ({ selected }: SubActivitiesState): IActivity {
    return selected;
  },
  isLoading({ isLoading }: SubActivitiesState): boolean {
    return isLoading;
  },
};
export default getters;
