import ISelectNumber from "@/models/select-number.model";

export const minBudget: ISelectNumber[] = [
  {
    name: '0',
    value: 0
  }, {
    name: '50.000',
    value: 50000
  }, {
    name: '250.000',
    value: 250000
  }, {
    name: '500.000',
    value: 500000
  }, {
    name: '1.000.000',
    value: 1000000
  }, {
    name: '5.000.000',
    value: 5000000
  }, {
    name: '10.000.000',
    value: 10000000
  }, {
    name: '20.000.000',
    value: 20000000
  }, {
    name: '50.000.000',
    value: 50000000
  },
];

export const maxBudget: ISelectNumber[] = [
  {
    name: '50.000',
    value: 50000
  }, {
    name: '250.000',
    value: 250000
  }, {
    name: '500.000',
    value: 500000
  }, {
    name: '1.000.000',
    value: 1000000
  }, {
    name: '5.000.000',
    value: 5000000
  }, {
    name: '10.000.000',
    value: 10000000
  }, {
    name: '20.000.000',
    value: 20000000
  }, {
    name: '50.000.000',
    value: 50000000
  }, {
    name: 'Más de 50.000.000',
    value: 50000001
  }
];

