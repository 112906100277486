import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, {TypeCompanyState} from "@/store/utils/type_company/state";


const typeCompanyModule: Module<TypeCompanyState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default typeCompanyModule;