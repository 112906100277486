import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, {BuildingsState} from "@/store/utils/buildings/state";


const buildingModule: Module<BuildingsState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default buildingModule;