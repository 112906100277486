import { Options, Vue } from 'vue-class-component';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';

@Options({
  components: {},
})
export default class RegisterWithCompany extends Vue {

  store = useStore<StateInterface>();


  get isMobile(): boolean {

    return window.innerWidth <= 800;
  }

  get isLogged(): boolean {
    return this.store.getters['auth/isUserLogin'];
  }
}
