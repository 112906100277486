import { IAddress } from "@/models/address.model";
import { CityTreeType } from "@/types/CityTreeType";

class VueTreeElementsChecked
{
  public static get(selectedCities: CityTreeType, allCities: IAddress[]): IAddress[]
  {
    const selectedIds: string[] = [];
    for (const community of selectedCities.nodes) {
      for (const province of community.nodes) {
        if (province.state.checked) {
          selectedIds.push(province.id);
        }
      }
    }

    return selectedIds.map((id: string): IAddress => {
      return allCities.find((city: IAddress) => {
        return city.id === id;
      });
    });
  }
}

export default VueTreeElementsChecked;
