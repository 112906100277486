import { Options, Vue } from 'vue-class-component';
import { IUser } from '@/models/user.model';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import useVuelidate from '@vuelidate/core';
import UiText from '@/components/form/ui-text/UiText.vue';
import { email, required } from '@vuelidate/validators';
import { validateEqual, validateRegexEmail } from '@/helpers/validate';
import toast from '@/plugins/toast.plugin';
import profile from '@/store/profile';
import BannerAddCompanyV2 from '@/components/smarts/banner-add-company-v2/BannerAddCompanyV2.vue';
import _ from 'lodash';

@Options({
  components: {
    UiText, BannerAddCompanyV2
  },
  validations: {
    password: {
      actual: { required, },
      new: { required, validateRegexEmail },
      confirmPass: { required, validateEqual: (a, b) => validateEqual(a, b['new']) },
    },
    profile: {
      name: {
        required,
      },
      lastname: {
        required,
      },
      email: {
        required,
        email
      },
    }
  }
})
export default class MyProfileMain extends Vue {
  v$: any = useVuelidate();
  store = useStore<StateInterface>();
  password = {
    actual: '',
    new: '',
    confirmPass: '',
  };
  activeMenu = '';
  profile: Partial<IUser> = {};

  async mounted(): Promise<void> {
    this.profile = await this.store.dispatch('profile/getProfile');
  }

  get companyCreated(): boolean {
    return !!this.store.getters['profile/getCompany']?.id;
  }

  async saveInfo(): Promise<void> {
    const result = await this.v$.profile.$validate();
    if ( !result) return;

    const newObj = { ...this.profile };
    delete newObj['id'];
    delete newObj['company'];

    await this.store.dispatch('profile/patchProfile', newObj);
  }

  async updatePassword(): Promise<void> {
    const result = await this.v$.password.$validate();
    if ( !result) return;
    const payload = {
      actual: this.password.actual,
      new: this.password.new,
    };
    const response = await this.store.dispatch('auth/changePass', payload);
    if (response) {
      toast.success('Su contraseña fue actualizada');
      this.password = {
        actual: '',
        new: '',
        confirmPass: '',
      };
      this.v$.password.$reset();

    }
  }
}
