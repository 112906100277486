import {Options, Vue} from 'vue-class-component';

@Options({
    emits: ["onClosed"],
    props: {
        id: String
    }
})
export default class UiDialog extends Vue {
    id = '';

    closed(): void {

        this.$emit('onClosed');
    }
}
