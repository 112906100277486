import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    items: Array,
    multiple: Boolean
  }
})
export default class UiAccordion extends Vue {
  items: { header: string; body: string }[] = [];
  active: { [k: string]: boolean } = {};
  multiple = false;

  onSwitcher(index: number): void {
    if (!this.multiple)
      this.active = {[this.labelTab(index)]: !this.active[this.labelTab(index)]};
    else
      this.active[this.labelTab(index)] = !this.active[this.labelTab(index)];

  }

  isActive(index: number): boolean {
    return this.active[this.labelTab(index)]
  }

  labelTab(index: number): string {
    return `q-${index}`;
  }
}