import { MutationTree } from 'vuex';
import { CompaniesState } from '@/store/companies/state';
import { IPaginate } from '@/models/paginate.model';
import { ICompany } from '@/models/company.model';


const mutation: MutationTree<CompaniesState> = {
  clear(state: CompaniesState): void {
    state.companies = [];
  },
  set(state: CompaniesState, { items, ...others }: IPaginate<ICompany>): void {
    state.companies = [...items];
    const nextPage = +others.pages > others.page
      ? others.page + 1
      : null;
    const lastPage = others.page >= 1
      ? others.page - 1
      : null;
    state.info = { items, ...others, nextPage, lastPage };
  },
  add(state: CompaniesState, { items, ...others }: IPaginate<ICompany>): void {
    state.companies.push(...items);
    const nextPage = +others.pages > others.page
      ? others.page + 1
      : null;
    const lastPage = others.page >= 1
      ? others.page - 1
      : null;
    state.info = { items, ...others, nextPage, lastPage };
  },
  setLoading(state: CompaniesState, loading: boolean): void {
    state.isLoading = loading;
  },
  select(state: CompaniesState, company: ICompany): void {
    const photo = company.images?.find((img) => {
      return img.type === "Photo";
    });
    if (photo) {
      company["photo"] = photo;
    }

    const logo = company.images?.find((img) => {
      return img.type === "Logo";
    });
    if (logo) {
      company["logo"] = logo;
    }

    const picture = company.images?.find((img) => {
      return img.type === "Picture";
    });
    if (picture) {
      company["picture"] = picture;
    }
    state.selected = company;
  },
  filter(state: CompaniesState, payload): void {
    localStorage.setItem('filter', JSON.stringify(payload));
    state.filter = payload;
  }

};


export default mutation;
