import { ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import { IPaginate } from '@/models/paginate.model';
import { IKeyValue } from '@/models/key-value.model';
import filterQuery from '@/helpers/filter-query';
import { HelperState } from '@/store/utils/helper/state';

const actions: ActionTree<HelperState, StateInterface> = {
  async getBy<T>(_, { url, params }: { url: string, params: IKeyValue }): Promise<T[]> {
    if (!url) {
      console.log(`url no valid ${url}`);
      return;
    }
    let _params = {};
    if (params && filterQuery({ ...params ?? {} }))
      _params = { search: filterQuery({ ...params ?? {} }) };
    _params['perPage'] = 1000;
    Object.keys(_params).forEach((k) => (_params[k] == null || params[k] === '') && delete _params[k]);
    const response = await api.get<IPaginate<T>>(`v1/${url.trim()}`,
      { params: _params });
    return response?.data?.items ?? [];
  },
};


export default actions;
