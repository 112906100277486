import { Options, Vue } from 'vue-class-component';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';


@Options({
  components: {
    UiFormMessage
  },
  props: {
    id: String,
    label: String,
    subLabel: String,
    type: String,
    placeholder: String,
    modelValue: undefined,
    vuelidate: undefined,
    disabled: Boolean
  }
})
export default class UiText extends Vue {
  label = '';
  id = '';
  type = 'text';
  modelValue = '';
  subLabel = '';
  placeholder = '';
  vuelidate: unknown;
  idInput = '';
  disabled = false;

  mounted(): void {
    this.idInput = this.id ? this.id :
      this.label ? `${this.label.split(' ').join('-')}-${new Date().getTime()}` : `label-${new Date().getTime()}`;
  }

  updatedEmit(v: unknown): void {
    this.$emit('update:modelValue', v);

  }

}
