import { ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import IMaster from '@/models/maestre.model';
import { IKeyValue } from '@/models/key-value.model';
import { TypeCompanyState } from '@/store/utils/type_company/state';
import filterQuery from '@/helpers/filter-query';
import UrlApi from '@/models/url-api.model';
import { IPaginate } from '@/models/paginate.model';

const url = `v1/${UrlApi.COMPANY_TYPE}`;

const actions: ActionTree<TypeCompanyState, StateInterface> = {
  async get({ commit }, extra?: IKeyValue): Promise<IMaster[]> {
    const params = extra
      ? { search: filterQuery(extra) }
      : {};
    const response = await api.get<IPaginate<IMaster>>(url, { params });
    response?.status === 200 && commit('setItems', response.data);
    return response?.data?.items;
  },
  async selected({
    commit,
    dispatch,
    getters
  }, company: IMaster): Promise<void> {
    if (!company) return;
    commit('select', company);
    if (getters['activities/getSelected'])
      dispatch('activities/select', undefined, { root: true });
    dispatch('activities/get', { companyType: company.id }, { root: true });
  }
};


export default actions;
