import {Options, Vue} from "vue-class-component";
import AdvancedSearchDialog from "@/components/smarts/dialog-advanced-search/DialogAdvancedSearch.vue";

@Options({
    components: {
        AdvancedSearchDialog
    },
})
export default class FindCompanies extends Vue {

}
