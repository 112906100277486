import {Options, Vue} from "vue-class-component";

@Options({
    components: {

    },
})
export default class BannerAddCompanyV2 extends Vue {


}
