import { Options, Vue } from 'vue-class-component';

@Options({
  emits: ['select'],
  watch: {
    tabActive(value, oldValue) {
      if ( !oldValue || value !== oldValue)
        this.activeTab = value;
    }
  },
  props: {
    msg: String,
    tabs: Array,
    tabActive: String
  }
})
export default class UiTab extends Vue {
  msg!: string;
  tabs = [];
  activeTab = '';
  tabActive = '';

  get tabPanelSlotName(): string {
    if ( !this.activeTab && this.tabs.length) {
      this.switchTab(this.tabs[0]);
    }

    return `tab-panel-${this.activeTab.split(' ').join('-').toLowerCase().trim()}`;
  }

  tabHeadSlotName(tabName: string): string {
    return `tab-head-${tabName}`;
  }

  mounted(): void {
    if (( !this.activeTab && this.tabActive) || (this.activeTab && this.tabActive !== this.activeTab))
      this.activeTab = this.tabActive;
  }


  switchTab(tabName: string): void {
    const emit = this.activeTab !== '';
    this.activeTab = tabName;
    if (emit)
      this.$emit('select', this.activeTab);
  }
}
