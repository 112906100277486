import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column w-100 my-4 tab-content" }
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "bg-white tab-content-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("a", {
          class: _normalizeClass(["ui-tab", {'active': _ctx.activeTab === tab}]),
          key: tab,
          onClick: ($event: any) => (_ctx.switchTab(tab))
        }, [
          _renderSlot(_ctx.$slots, _ctx.tabHeadSlotName(tab), {}, () => [
            _createTextVNode(_toDisplayString(tab), 1)
          ])
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, _ctx.tabPanelSlotName)
    ])
  ]))
}