import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import { required } from '@vuelidate/validators';
import UiText from '@/components/form/ui-text/UiText.vue';
import { validateEqual } from '@/helpers/validate';
import toast from '@/plugins/toast.plugin';
import { ICompany } from '@/models/company.model';

@Options({
  components: {
    UiText
  },
  validations: {
    form: {
      email: { required },
      password: { required },
      name: { required },
      lastname: { required },
      confirmPass: { required, validateEqual: (a, b) => validateEqual(a, b['password']) },
    }
  }
})
export default class Register03 extends Vue {

  form = {
    lastname: '',
    password: '',
    name: '',
    email: '',
    confirmPass: ''
  };
  v$: any = useVuelidate();
  store = useStore<StateInterface>();

  async mounted(): Promise<void> {
    this.form = { ...await this.store.getters['profile/getProfile'] };
  }

  get isLogged(): boolean {
    return this.store.getters['auth/isUserLogin'];
  }

  get company(): ICompany {
    return this.store.getters['profile/getCompany'];
  }

  async onRegister(): Promise<void> {
    const result = await this.v$.$validate();
    if ( !result) return;
    const response = await this.store.dispatch('auth/register', this.form);
    await this.store.dispatch('profile/createCompany', this.company);

    if (response) {
      toast.success('Registrado con exito ya puedes iniciar sesion');
      await this.$router.push('/');
    }


  }
}
