import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import { required } from '@vuelidate/validators';
import UiText from '@/components/form/ui-text/UiText.vue';
import isDev from '@/helpers/is-dev';
import faker from '@faker-js/faker';

@Options({
  components: {
    UiText
  },
  validations: {
    name: { required }
  }
})
export default class Register01 extends Vue {

  v$: any = useVuelidate();
  store = useStore<StateInterface>();
  name = isDev
    ? faker.company.companyName()
    : '';

  mounted(): void {
    this.name = this.store.getters['profile/getCompany']?.name ?? this.name;
  }

  async onContinue(): Promise<void> {

    const result = await this.v$.$validate();

    if ( !result) return;
    await this.store.commit('profile/patchCompany', { name: this.name });
    setTimeout(() => this.$router.push('/rwc/02'), 500);

  }
}
