import {GetterTree} from "vuex";
import {AuthState} from "@/store/auth/state";
import {StateInterface} from "@/store";
import { IUser } from '@/models/user.model';

const getters: GetterTree<AuthState, StateInterface> = {
    isUserLogin({token}: AuthState): boolean {
        return !!token?.access;
    },
    user({user}: AuthState): IUser {
        return user;
    },
    isLoading({isLoading}: AuthState): boolean {
        return isLoading;
    },
    getTokenAccess({token}: AuthState): string {
        return token.access;
    },
    getTokenRefresh({token}: AuthState): string {
        return token.refresh;
    },
}
export default getters;
