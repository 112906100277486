import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-3 input-ui-text" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["type", "value", "id", "disabled", "placeholder"]
const _hoisted_4 = {
  key: 1,
  class: "text-cement-gray"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_form_message = _resolveComponent("ui-form-message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.idInput,
          class: "form-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: _ctx.type,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatedEmit($event.target.value))),
      autocomplete: "off",
      class: _normalizeClass(["form-control", {'is-invalid':_ctx.vuelidate?.$silentErrors.length && _ctx.vuelidate.$dirty}]),
      id: _ctx.idInput,
      disabled: _ctx.disabled,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.vuelidate?.$touch())),
      placeholder: _ctx.placeholder
    }, null, 42, _hoisted_3),
    (_ctx.subLabel)
      ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.subLabel), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_ui_form_message, { vuelidate: _ctx.vuelidate }, null, 8, ["vuelidate"])
  ]))
}