import { ActionContext, ActionTree } from 'vuex';
import { StateInterface } from '@/store';
import api from '@/store/api';
import { IPaginate } from '@/models/paginate.model';
import { IKeyValue } from '@/models/key-value.model';
import { ActivitiesState } from '@/store/utils/activities/state';
import filterQuery from '@/helpers/filter-query';
import IActivity from '@/models/activity.model';
import UrlApi from '@/models/url-api.model';

const url = `v1/${UrlApi.ACTIVITIES}`;
const actions: ActionTree<ActivitiesState, StateInterface> = {
  async get({ commit }, extra?: IKeyValue): Promise<void> {
    const extras = { ...extra ?? {} };
    if ( !extras || extras['main'] == undefined)
      extras['main'] = true;

    const params = { search: filterQuery(extras) };
    params['perPage'] = 200;

    const { data, status } = await api.get<IPaginate<IActivity>>(url, { params });
    if (status === 200) {
      commit('setItems', data);
    }
  },
  async getBy(_, extra?: IKeyValue): Promise<IActivity[]> {
    const extras = { ...extra ?? {} };
    if ( !extras || extras['main'] == undefined)
      extras['main'] = true;

    const params = { search: filterQuery(extras) };
    const response = await api.get<IPaginate<IActivity>>(url, { params });
    return response?.data?.items ?? [];
  },

  async select({ commit, dispatch, rootState }: ActionContext<ActivitiesState, StateInterface>,
    activity?: IActivity): Promise<void>
    {
      commit('select', activity);

      if (rootState?.sub_activities?.selected) {
        await dispatch('sub_activities/select', undefined, { root: true });
      }
      if (rootState?.sub_activities?.items?.items.length) {
        commit('sub_activities/setItems', undefined, { root: true });
      }

      if (activity?.id) {
        const { type_company }: StateInterface = rootState;
        const searcher = {
          companyType: type_company?.selected?.id ?? '',
          parent: activity?.id
        };
        await dispatch('sub_activities/get', searcher, { root: true });
      }
    }
};


export default actions;
