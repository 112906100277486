export const transformOnlyId = (filter: any, withArray = false) => {

  if (Array.isArray(filter)) {
    return filter.filter(e => !!e).map(obj => transformOnlyId(obj, true));
  }
  if (typeof filter === 'object' && filter.id && withArray) {
    return { id: filter.id };
  }
  if (typeof filter === 'object' && filter.id && !withArray) {
    return filter.id;
  }
  if (typeof filter !== 'object')
    return filter;
  const newObj = {};
  Object.keys(filter).forEach((key: string) => {
    newObj[key] = transformOnlyId(filter[key]);
  });

  return newObj;

};

export default transformOnlyId;
