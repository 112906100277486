import { GetterTree } from 'vuex';
import { StateInterface } from '@/store';
import IMaster from '@/models/maestre.model';
import { ActivitiesState } from '@/store/utils/activities/state';
import IActivity from '@/models/activity.model';

const getters: GetterTree<ActivitiesState, StateInterface> = {
  getPage({ items }: ActivitiesState): number {
    return items.page ?? 1;
  },
  getTotal({ items }: ActivitiesState): number {
    return items.total ?? 0;
  },
  getItems({ items }: ActivitiesState): IMaster[] {
    return items?.items ?? [];
  },
  getSelected({ selected }: ActivitiesState): IActivity {
    return selected ;
  },
  isLoading({ isLoading }: ActivitiesState): boolean {
    return isLoading;
  },
};
export default getters;
