import { Options, Vue } from 'vue-class-component';
import UiText from '@/components/form/ui-text/UiText.vue';
import UiCheckBox from '@/components/form/ui-checkbox/UiCheckBox.vue';
import UiTextArea from '@/components/form/ui-textarea/UiTextArea.vue';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';
import UiInputImg from '@/components/form/ui-input-img/UiInputImg.vue';
import IMaster from '@/models/maestre.model';
import ISelectNumber from '@/models/select-number.model';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import UiSelect from '@/components/form/ui-select/UiSelect.vue';
import UrlApi from '@/models/url-api.model';
import IActivity from '@/models/activity.model';
import { minSurface, maxSurface } from '@/helpers/surfaces';
import { maxBudget, minBudget } from '@/helpers/budget';

@Options({
  components: {
    UiText,
    UiCheckBox,
    UiTextArea,
    UiFormMessage,
    UiInputImg,
    UiSelect
  },
  props: {
    form: Object,
    type: String,
    txtButton: String,
    vuelidate: undefined
  },
})
export default class CompanyFunction extends Vue {
  selectedSubActivity: any = '';
  urlApi = UrlApi;
  form = {
    area: {
      min: 0,
      max: 0
    },
    budget: {
      min: 0,
      max: 0
    },
    activities: [],
    buildings: [],
    type: null,
  };
  type = '';
  txtButton = '';
  vuelidate;
  store = useStore<StateInterface>();
  timer: any;
  apiUrl = UrlApi;
  subActivityKey = 0;
  surfaceMinimum = minSurface;
  surfaceMaximum = maxSurface;
  budgetMinimum = minBudget;
  budgetMaximum = maxBudget;

  get typeCompany(): IMaster[] {
    return this.store.getters['type_company/getItems'];
  }

  get typeBuildings(): IMaster[] {
    return this.store.getters['buildings/getItems'];
  }

  get typeWorks(): IMaster[] {
    return this.store.getters['constructions/getItems'];
  }

  get employees(): IMaster[] {
    return this.store.getters['employees/getItems'];
  }

  selectActivity(activity: IActivity): void {
    this.store.dispatch('activities/select', activity);
    this.subActivityKey++;
  }

  get selectedTypeCompany(): string {
    if (this.type)
      return this.type;
    return this.form.type.id;
  }

  get activitySelected(): IActivity {
    return this.store.getters['activities/getSelected'];
  }

  get typeActivities(): IActivity[] {
    return this.store.getters['activities/getItems'];
  }

  get typeSubActivities(): IActivity[] {
    return this.store.getters['sub_activities/getItems'] ?? [];
  }

  addActivity() {
    if(this.selectedSubActivity) {
      if(this.form['activities'] !== undefined && this.form['activities'].length > 0) {
        const isInList = this.form['activities'].find(e => e.id === this.selectedSubActivity.id);
        if(!isInList) {
          this.form['activities'].push(this.selectedSubActivity);
        }
      } else {
        const activities = [];
        activities.push(this.selectedSubActivity);
        this.form['activities'] = activities;
      }
      this.selectedSubActivity = null;
    }
  }

  removeActivity(e: IActivity) {
    this.form['activities'].forEach((value, index) => {
      if (value.id == e.id) {
        this.form['activities'].splice(index, 1);
      }
    });
  }

  async updateChange(): Promise<void> {
    this.$emit('update:form', this.vuelidate?.$model);
  }

  addArray(key: string, item: IMaster): void {
    if ( !this.form[key])
      this.form[key] = [];
    if (this.inArray(key, item)) {
      this.form[key] = this.form[key].filter(e => e.id !== item.id);
    } else
      this.form[key].push(item);
  }

  inArray(key: string, { id }: IMaster): boolean {
    const _arrayList = this.form[key] ?? [];
    return (_arrayList && _arrayList.length && _arrayList.find(e => e.id == id) != null) == true;
  }

  get surfaceMinimumValue(): string {
    return this.surfaceMinimum.find(e => e.value === this.form.area.min).name;
  }

  get surfaceMaximumValue(): string {
    if(this.form.area.max)
      return this.surfaceMaximum.find(e => e.value === this.form.area.max).name;

    return '';
   }

  get budgetMinimumValue(): string {
    return this.budgetMinimum.find(e => e.value === this.form.budget.min).name;
  }

  get budgetMaximumValue(): string {
    if(this.form.budget.max)
      return this.budgetMaximum.find(e => e.value === this.form.budget.max).name;

    return '';
  }
}
