import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, {ActivitiesState} from "@/store/utils/activities/state";


const activitiesModule: Module<ActivitiesState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default activitiesModule;