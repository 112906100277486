import {IPaginate} from "@/models/paginate.model";
import {IAddress, IProvince} from "@/models/address.model";

export interface CitiesState {
    isLoading: boolean;
    items: IPaginate<IAddress>,
    provinces: IProvince[]
    provinceActive: IProvince;
}

function state(): CitiesState {
    return {
        isLoading: false,
        items: undefined,
        provinces: [],
        provinceActive: undefined,
    }
}

export default state;
