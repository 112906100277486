import IMaster from "@/models/maestre.model";
import {IPaginate} from "@/models/paginate.model";

export interface ConstructionState {
    isLoading: boolean;
    items: IPaginate<IMaster>
}

function state(): ConstructionState {
    return {
        isLoading: false,
        items: undefined,
    }
}

export default state;
