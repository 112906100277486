import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, {SubActivitiesState} from "@/store/utils/sub_activities/state";


const subActivitiesModule: Module<SubActivitiesState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default subActivitiesModule;