import {Module} from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {StateInterface} from "@/store";
import state, { CompaniesState } from '@/store/companies/state';


const companiesModule: Module<CompaniesState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default companiesModule;
