import { MutationTree } from 'vuex';
import { HelperState } from '@/store/utils/helper/state';

const mutation: MutationTree<HelperState> = {
    setLoading(state: HelperState, loading: boolean) {
        state.isLoading = loading;
    },
}


export default mutation;
