import { Options, Vue } from 'vue-class-component';
import UiText from '@/components/form/ui-text/UiText.vue';
import UiCheckBox from '@/components/form/ui-checkbox/UiCheckBox.vue';
import UiTextArea from '@/components/form/ui-textarea/UiTextArea.vue';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';
import UiInputImg from '@/components/form/ui-input-img/UiInputImg.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import useVuelidate from '@vuelidate/core';
import { IAddress } from '@/models/address.model';
import UrlApi from '@/models/url-api.model';
import Tree from "vuejs-tree"
import { CityTreeType } from '@/types/CityTreeType';
import VueTreeElementsGetter from '@/application/activities/VueTreeElementsGetter';
import VueTreeElementsChecked from '@/application/activities/VueTreeElementsChecked';
import VueTreeCheckElementsAllItsChildrenAreChecked from '@/application/activities/VueTreeCheckElementsAllItsChildrenAreChecked';

@Options({
  components: {
    UiText,
    UiCheckBox,
    UiTextArea,
    UiFormMessage,
    UiInputImg,
    Tree
  },
  props: {
    form: Object,
    txtButton: String,
    vuelidate: undefined
  },
})
export default class CompanyPerformance extends Vue {
  form: IAddress[] = [];
  txtButton = '';
  vuelidate;
  store = useStore<StateInterface>();
  v$: any = useVuelidate();
  urlApi = UrlApi;
  cities: IAddress[] = [];
  cityTree: CityTreeType|null = null;

  async updateChange(): Promise<void> {
    this.$emit('update:form', this.vuelidate?.$model);
  }

  addLocation($event): void {
    this.$emit('update:form', $event);
  }

  async loadOptions(): Promise<void> {
    const params = { province: '' };
    this.cities = await this.store.dispatch('helper/getBy', { url: this.urlApi.PROVINCES, params });
    this.cityTree = VueTreeElementsGetter.get(this.cities, this.form);
  }

  nodeSelected() {
    this.$emit('update:form', VueTreeElementsChecked.get(this.cityTree, this.cities));
    VueTreeCheckElementsAllItsChildrenAreChecked.check(this.cityTree);
  }

  mounted() {
    this.loadOptions();
  }
}
