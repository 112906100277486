import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.vuelidate === undefined)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "UiFormMessage invalid-feedback",
        style: _normalizeStyle({'display': _ctx.isShow && _ctx.message?'block':'none'})
      }, _toDisplayString(_ctx.message), 5))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vuelidate.$silentErrors, (msj, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "invalid-feedback",
            style: _normalizeStyle({'display': _ctx.vuelidate.$silentErrors.length && _ctx.vuelidate.$dirty?'block':'none'}),
            key: 'mss-'+index
          }, _toDisplayString(msj.$message), 5))
        }), 128))
      ]))
}