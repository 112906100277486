import { Options, Vue } from 'vue-class-component';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import BannerAddCompanyV2 from '@/components/smarts/banner-add-company-v2/BannerAddCompanyV2.vue';
import { ICompany } from '@/models/company.model';
import { clearPropertiesEmpty, deletePropertyEqual } from '@/helpers/utils';

@Options({
  components: {
    BannerAddCompanyV2
  }
})
export default class MyProfile extends Vue {
  store = useStore<StateInterface>();

  async onLogout(): Promise<void> {
    await this.store.commit('auth/logout');
    await this.$router.push('/');
  }

  get companyCreated(): boolean {
    return !!this.store.getters['profile/getCompany']?.id;
  }

  get menuProfile(): [] {
    return this.store.getters['profile/getMenuProfile'] ?? [];
  }

  get company(): ICompany {
    return this.store.getters['profile/getCompany'];
  }


}
