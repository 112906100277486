import { Options, Vue } from 'vue-class-component';
import UiText from '@/components/form/ui-text/UiText.vue';
import UiCheckBox from '@/components/form/ui-checkbox/UiCheckBox.vue';
import UiTextArea from '@/components/form/ui-textarea/UiTextArea.vue';
import UiFormMessage from '@/components/form/ui-form-message/UiFormMessage.vue';
import UiInputImg from '@/components/form/ui-input-img/UiInputImg.vue';
import { validateRegexEmail, validateRegexUrl } from '@/helpers/validate';

@Options({
  components: {
    UiText,
    UiCheckBox,
    UiTextArea,
    UiFormMessage,
    UiInputImg,
  },
  props: {
    form: Object,
    txtButton: String,
    vuelidate: undefined
  },
})
export default class CompanyContact extends Vue {
  form = {
    networks: []
  };
  txtButton = '';
  vuelidate;


  async updateChange(): Promise<void> {
    this.$emit('update:form', this.vuelidate?.$model);
  }

  setNetWork(name: string, url: string): void {
    if ( !this.form.networks) this.form.networks = [];
    this.form.networks = this.form.networks.filter(e => e.name !== name);
    this.form.networks.push({ url, name });
  }

  getNetwork(name: string): string {
    return (this.form.networks ?? []).find(e => e.name === name)?.url ?? '';
  }

  isValidUrl(email: string): boolean {
    return true;
  }
}
