import { IPaginate } from '@/models/paginate.model';
import { IAddress } from '@/models/address.model';

export interface ProvincesState {
  isLoading: boolean;
  items: IPaginate<IAddress>,
}

function state(): ProvincesState {
  return {
    isLoading: false,
    items: undefined,
  };
}

export default state;
