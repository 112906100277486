import { Options, Vue } from 'vue-class-component';
import UiTab from '@/components/layout/ui-tab/UiTab.vue';
import UiSelect from '@/components/form/ui-select/UiSelect.vue';
import UiDialog from '@/components/layout/ui-dialog/UiDialog.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import IMaster from '@/models/maestre.model';
import UiCheckBox from '@/components/form/ui-checkbox/UiCheckBox.vue';
import UiText from '@/components/form/ui-text/UiText.vue';
import IActivity from '@/models/activity.model';
import UrlApi from '@/models/url-api.model';
import toast from '@/plugins/toast.plugin';
import { clearPropertiesEmpty } from '@/helpers/utils';
import { maxSurface, minSurface } from '@/helpers/surfaces';
import { maxBudget, minBudget } from '@/helpers/budget';

@Options({
  components: {
    UiTab,
    UiSelect,
    UiDialog,
    UiCheckBox,
    UiText
  },
  props: {
    msg: String
  }
})
export default class Searcher extends Vue {
  msg!: string;
  urlApi = UrlApi;
  form = this.instanceForm;
  store = useStore<StateInterface>();
  provinceKey = 0;
  subActivityKey = 0;
  surfaceMinimum = minSurface;
  surfaceMaximum = maxSurface;
  budgetMinimum = minBudget;
  budgetMaximum = maxBudget;

  get ids(): string[] {
    return !this.typeCompany
      ? []
      : this.typeCompany.map(e => e.name);
  }

  get idsPanel(): string[] {
    return !this.typeCompany
      ? []
      : this.typeCompany.map(e => ('tab-panel-' + e.name).trim().toLowerCase());
  }

  get instanceForm(): any {
    return {
      provinceName: null,
      type: '',
      employees: [],
      buildings: [],
      constructions: [],
      area: {
        min: 0,
        max: 0
      },
      budget: {
        min: 0,
        max: 0
      }
    };
  }

  get typeCompany(): IMaster[] {
    return this.store.getters['type_company/getItems'];
  }

  get typeActivities(): IActivity[] {
    return this.store.getters['activities/getItems'];
  }

  get typSubActivities(): IActivity[] {
    return this.store.getters['sub_activities/getItems'] ?? [];
  }

  get typeBuildings(): IMaster[] {
    return this.store.getters['buildings/getItems'];
  }

  get provinces(): IMaster[] {
    return this.store.getters['provinces/getItems'];
  }

  get numEmployees(): IMaster[] {
    return this.store.getters['employees/getItems'];
  }

  get typeWorks(): IMaster[] {
    return this.store.getters['constructions/getItems'];
  }

  async onSearch(): Promise<void> {
    const filter = clearPropertiesEmpty({
      ...this.form,
      activities: [],
    });
    filter["type"] = this.companySelect;
    // if (this.activitySelected)
    //   filter.activities =
    //     [{ ...this.activitySelected }, { ...this.subActivitySelected }].filter(e => !!e && Object.values(e).length);
    if (this.activitySelected)
      filter["parent"] = [{ ...this.activitySelected}].filter(e => !!e && Object.values(e).length);
    if (this.subActivitySelected)
      filter.activities =  [{ ...this.subActivitySelected }].filter(e => !!e && Object.values(e).length);

    if (filter.area?.min > filter.area?.max) {
      toast.error('La superficie mínima no puede ser mayor a la máxima.');
      return;
    }

    if (filter.budget?.min > filter.budget?.max) {
      toast.error('El presupuesto mínimo no puede ser mayor a la máximo.');
      return;
    }

    await this.store.dispatch('companies/saveFilter', filter);
    await this.$router.push({ path: 'companies' });
  }

  get activitySelected(): IActivity {
    return this.store.getters['activities/getSelected'];
  }

  get getCity(): IActivity {
    return this.filtersActive?.provinceName ?? null;
  }

  get subActivitySelected(): IActivity {
    return this.store.getters['sub_activities/getSelected'];
  }


  selectActivity(_: IActivity): void {
    this.store.dispatch('activities/select', _);
    this.validateReload();
    this.subActivityKey += 1;
  }

  validateReload(): void {
    if (this.$route.path === '/companies') {
      this.onSearch();
    }
  }

  selectSubActivity(_: IActivity): void {
    this.store.dispatch('sub_activities/select', _);
    this.validateReload();
  }

  get typeCompanyActive(): IMaster {
    if ( !this.typeCompany) return null;
    const active = this.$refs.searchTab['activeTab'];
    return this.typeCompany.find(e => e.name == active);
  }

  get companySelect(): IMaster {
    return this.store.getters['type_company/getSelected'];
  }

  get selectedTypeCompany(): string {
    return this.companySelect?.id;
  }

  reloadTab(_): void {
    const company = this.typeCompany.find(e => e.name == _);
    this.store.dispatch('type_company/selected', company);
    this.selectActivity(null);
    this.selectSubActivity(null);
    this.store.dispatch('companies/saveFilter', { type: company });
  }

  addArray(key: string, item: IMaster): void {
    if ( !this.form[key])
      this.form[key] = [];
    if (this.inArray(key, item)) {
      this.form[key] = this.form[key].filter(e => e.id !== item.id);
    } else
      this.form[key].push(item);
  }

  inArray(key: string, { id }: IMaster): boolean {
    const _arrayList = this.form[key] ?? [];
    return (_arrayList && _arrayList.length && _arrayList.find(e => e.id == id) != null) == true;
  }

  async mounted(): Promise<void> {

    if (this.$route.path !== '/')
      setTimeout(() => {
        if (this.filtersActive) {

          Object.keys(this.form).forEach(key => {
            if (this.filtersActive[key])
              this.form[key] = this.filtersActive[key];
          });
        }
        this.$nextTick();
        this.$forceUpdate();
      }, 2000);
    else {
      this.store.dispatch('companies/saveFilter', {});
      this.selectActivity(null);
    }

  }

  get filtersActive(): any {
    return this.store.getters['companies/filterActive'];
  }

  get infoFilters(): number {
    const { budget, area, buildings, constructions, employees } = this.form;
    return Object.values(clearPropertiesEmpty({
      budget,
      area,
      buildings,
      constructions,
      employees
    })).filter(e => !!e).length;
  }

  async clearFilter(): Promise<void> {
    // await this.store.dispatch('companies/saveFilter',
    //   { type: this.companySelect, provinceName: this.filtersActive.provinceName });
    this.form = {
      provinceName: null,
      type: '',
      employees: [],
      buildings: [],
      constructions: [],
      area: {
        min: 0,
        max: 0
      },
      budget: {
        min: 0,
        max: 0
      }
    };
    this.$forceUpdate();
    this.provinceKey += 1;
    await this.store.dispatch('companies/saveFilter',
      { type: this.companySelect });
  }

  get isMobile(): boolean {
    return window.innerWidth <= 800;
  }
}
