import IMaster from '@/models/maestre.model';
import { IPaginate } from '@/models/paginate.model';

export interface TypeCompanyState {
  isLoading: boolean;
  items: IPaginate<IMaster>,
  selected?: IMaster
}

function state(): TypeCompanyState {
  return {
    isLoading: false,
    items: undefined,
    selected: undefined
  };
}

export default state;
