import { CityTreeType } from "@/types/CityTreeType";

class VueTreeCheckElementsAllItsChildrenAreChecked
{
  public static check(main: CityTreeType): void
  {
    let allSubNodesChecked = true;
    main.nodes.forEach((subNode: CityTreeType) => {
      const allNodesAreChecked = !subNode.nodes.find((sub: CityTreeType) => {
        return sub.state.checked === false;
      });

      allSubNodesChecked = allSubNodesChecked && allNodesAreChecked;

      subNode.state.checked = allNodesAreChecked;
    });

    main.state.checked = allSubNodesChecked;
  }
}

export default VueTreeCheckElementsAllItsChildrenAreChecked;
