
import { Options, Vue } from 'vue-class-component';
import Footer from '@/components/smarts/footer/Footer.vue';
import NavBar from '@/components/smarts/ui-nav-bar/nav-bar/NavBar.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import IActivity from '@/models/activity.model';
import IMaster from '@/models/maestre.model';
import router from '@/router';

@Options({
  components: {
    NavBar,
    Footer
  },
})
export default class App extends Vue {

  store = useStore<StateInterface>();

  async mounted(): Promise<void> {
    await this.store.dispatch('auth/isLogin');
    await this.store.dispatch('buildings/get');
    await this.store.dispatch('constructions/get');
    await this.store.dispatch('employees/get');
    await this.store.dispatch('provinces/get');
    const tupes = await this.store.dispatch('type_company/get');

    const type: any = localStorage.getItem('type');

    this.store.dispatch('type_company/selected',
        type && type !== 'undefined'
            ? JSON.parse(type) as IMaster
            : tupes[0]);

    const ac: any = localStorage.getItem('activity');
    if (ac !== 'undefined') {
      this.store.dispatch('activities/select', JSON.parse(ac) as IActivity);
    }
    const sc: any = localStorage.getItem('subActivity');
    if (sc !== 'undefined') {
      this.store.dispatch('sub_activities/select', JSON.parse(sc) as IActivity);
    }
    let fc: any = localStorage.getItem('filter');
    if (fc && fc !== 'undefined') {
      fc = JSON.parse(fc);

      fc?.buildings && !Array.isArray(fc.buildings) && (fc['buildings'] = Object.values(fc.buildings));
      fc?.constructions && !Array.isArray(fc.constructions) && (fc['constructions'] = Object.values(fc.constructions));
      fc?.employees && !Array.isArray(fc.employees) && (fc['employees'] = Object.values(fc.employees));
      if (fc)
        this.store.dispatch('companies/saveFilter', fc);

    }
  }

  get typeCompany(): IMaster[] {
    return this.store.getters['type_company/getItems'];
  }
}


