import { Options, Vue } from 'vue-class-component';

import UiAccordion from '@/components/layout/ui-accordion/UiAccordion.vue';
import Searcher from '@/components/smarts/searcher/Searcher.vue';
import NavBar from '@/components/smarts/ui-nav-bar/nav-bar/NavBar.vue';
import Footer from '@/components/smarts/footer/Footer.vue';
import CardProject from '@/components/smarts/card-project/CardProject.vue';

import QuotesSlider from '@/components/smarts/quotes-slider/QuotesSlider.vue';
import CompaniesCloseMe from '@/components/smarts/companies-close-me/CompaniesCloseMe.vue';
import FindCompanies from '@/components/smarts/find-companies/FindCompanies.vue';
import FAQs from '@/components/smarts/FAQs/FAQs.vue';
import BannerNewsletter from '@/components/smarts/banner-newsletter/BannerNewsletter.vue';
import BannerJoin from '@/components/smarts/banner-join/BannerJoin.vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';
import IMaster from '@/models/maestre.model';
import IActivity from '@/models/activity.model';


@Options({
  components: {
    UiAccordion,
    Searcher,
    NavBar,
    Footer,
    CardProject,
    QuotesSlider,
    CompaniesCloseMe,
    FAQs,
    FindCompanies,
    BannerNewsletter,
    BannerJoin
  },
})
export default class HomeView extends Vue {
  activeServices = 'c';
  store = useStore<StateInterface>();

  services: { key: string; value: string }[] = [
    {
      key: 'Demoliciones',
      value: '458'
    },
    {
      key: 'Fachadas',
      value: '88'
    },
    {
      key: 'Instalaciones',
      value: '88'
    },
    {
      key: 'Cubiertas',
      value: '455418'
    },
    {
      key: 'Residuos',
      value: '88'
    },
    {
      key: 'Cimentaciones',
      value: '458'
    },
    {
      key: 'Estructuras',
      value: '155'
    },
    {
      key: 'Acondicionamiento de terreno',
      value: '458'
    },
    {
      key: 'Carpintería, cerrajería, vidrios y protecciones solares',
      value: '541'
    },
    {
      key: 'Firmes y pavimentos urbanos',
      value: '541'
    },
  ];

  get isLogged(): boolean {
    return this.store.getters['auth/isUserLogin'];
  }

  get type(): IMaster[] {
    return this.store.getters['type_company/getItems'];
  }

  activeType(type: IMaster): void {
    this.store.dispatch('type_company/selected', type);
  }

  get typeActive(): IMaster {
    return this.store.getters['type_company/getSelected'];
  }

  get activities(): IActivity[] {
    return this.store.getters['activities/getItems'];
  }

  goAllServices(): void {
    window.scroll(0, 0);
    this.$router.push('/companies');
  }
}
